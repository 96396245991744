<div class="page-center-container">
  <h2>Оновлення довідників</h2>

  <form [formGroup]="updateForm">
    <mat-form-field>
      <mat-label>Тип довідника</mat-label>
      <mat-select formControlName="type">
        <mat-option value="op">Освітні програми</mat-option>
        <mat-option value="teacher">Науково-педагогічні працівники</mat-option>
        <mat-option value="hei">Суб'єкти освітньої діяльності</mat-option>
      </mat-select>
      <mat-error>Це поле є обов'язковим</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Дата актуальності даних у файлі</mat-label>
      <input matInput
             formControlName="dataUpdatedOn"
             [matDatepicker]="$any(dataUpdatedOnDatePicker)"
             [readonly]="true"
             [max]="today">
      <mat-datepicker-toggle matSuffix [for]="dataUpdatedOnDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dataUpdatedOnDatePicker></mat-datepicker>
      <mat-error>Це поле є обов'язковим</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Файл довідника</mat-label>
      <ngx-mat-file-input formControlName="file" accept=".xlsx"></ngx-mat-file-input>
      <mat-hint>Допустимий формат файла – XLSX</mat-hint>
    </mat-form-field>
  </form>

  <div class="button-row">
    <button mat-raised-button color="primary" [disabled]="!updateForm.valid" (click)="upload()">Завантажити оновлення</button>
  </div>
</div>
