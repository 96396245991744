import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { map, Observable, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { currentTokenExpiryCheckSelector } from '../state/auth/auth.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  currentToken$ = this.store.select(currentTokenExpiryCheckSelector);

  constructor(private store: Store<AppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.currentToken$.pipe(
      take(1),
      map((token) =>
        req.clone({
          setHeaders: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        }),
      ),
      switchMap((req) => next.handle(req)),
    );
  }
}
