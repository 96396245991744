import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { IJwt } from '../../interfaces/identification/identification.interfaces';

export const userAuthorizedSelector = (state: AppState) =>
  state.auth.accessToken !== null;

export const currentTokenSelector = (state: AppState) => state.auth.accessToken;

export const tokenDecodeSelector = createSelector(
  userAuthorizedSelector,
  currentTokenSelector,
  (isAuthorized, token) =>
    isAuthorized ? (jwt_decode(token as string) as IJwt) : null,
);

export const tokenExpiredSelector = createSelector(
  tokenDecodeSelector,
  (token) => {
    if (token != null) {
      const expiryDate = new Date(0);
      expiryDate.setUTCSeconds(token.exp);
      return expiryDate < new Date();
    } else {
      return null;
    }
  },
);

export const currentTokenExpiryCheckSelector = createSelector(
  tokenExpiredSelector,
  currentTokenSelector,
  (expired, token) => (expired ? null : token),
);

export const currentUserSelector = createSelector(
  tokenDecodeSelector,
  (user) => user?.sub,
);

export const currentUserIsHeiSelector = createSelector(
  tokenDecodeSelector,
  (user) => user?.sub.hei !== undefined,
);

export const currentUserHeiSelector = createSelector(
  tokenDecodeSelector,
  (user) => user?.sub.hei,
);

export const currentUserIsMonSelector = createSelector(
  tokenDecodeSelector,
  (token) => !!token?.sub.isMon,
);

export const currentUserIsNaqaSelector = createSelector(
  tokenDecodeSelector,
  (token) => !!token?.sub.isNaqa,
);

export const currentUserIsPrivatePersonSelector = createSelector(
  tokenDecodeSelector,
  (token) => !!token?.sub.isPrivatePerson,
);

export const currentUserIsNotMonOrNaqaSelector = createSelector(
  userAuthorizedSelector,
  tokenDecodeSelector,
  (isAuthorized, token) =>
    isAuthorized ? !token?.sub.isMon && !token?.sub.isNaqa : false,
);
