import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Moment } from 'moment';
import { take } from 'rxjs';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { loadingOff, loadingOn } from '../../state/loading/loading.actions';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
})
export class UpdateComponent implements OnInit {
  readonly today = new Date();
  updateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private snack: SnackbarService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.updateForm = this.fb.group({
      type: ['', [Validators.required]],
      dataUpdatedOn: ['', [Validators.required]],
      file: ['', [Validators.required]],
    });
  }

  upload() {
    this.store.dispatch(loadingOn());
    this.api
      .uploadUpdate(
        this.updateForm.controls.type.value,
        (this.updateForm.controls.dataUpdatedOn.value as Moment).format(),
        this.updateForm.controls.file.value as File,
      )
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(loadingOff());
        this.updateForm.reset();
        setTimeout(() => {
          this.snack.openSnackBar('Файл завантажено успішно!');
        }, 500);
      });
  }
}
