import { Component, OnInit } from '@angular/core';
import { version, supportEmail } from '../../misc/const';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent {
  readonly version = version;
  readonly supportEmail = supportEmail;

  constructor() {}
}
