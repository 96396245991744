<h2>Повідомлення</h2>

<div fxLayout="row" class="margins-12">
  <ng-container *ngrxLet="filter$; let filter">
    <div fxFlex="95">
      <app-filter-chips *ngIf="filter !== undefined" [filter]="filter"></app-filter-chips>
    </div>
  </ng-container>

  <div fxFlex="5">
    <button mat-icon-button
            *ngIf="!(filterSet$ | ngrxPush)"
            color="primary"
            (click)="openFilterDialog()"
            matTooltip="Встановити фільтр">
      <mat-icon>filter_list</mat-icon>
    </button>

    <button mat-icon-button
            *ngIf="filterSet$ | ngrxPush"
            color="warn"
            (click)="clearFilter()"
            matTooltip="Скинути фільтр">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

</div>

<div [hidden]="!(messagesNotZero$ | ngrxPush)">
  <app-messages-display [messages]="messages$ | ngrxPush" [smallIcons]="false" [showLinkToDefense]="true"></app-messages-display>

  <mat-paginator [length]="messagesTotalCount$ | ngrxPush"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 showFirstLastButtons="true"></mat-paginator>
</div>

<h4 [hidden]="messagesNotZero$ | ngrxPush">Схоже, нічого не знайдено...</h4>



