<h2>Захисти</h2>


<div fxLayout="row" class="margins-12">
  <ng-container *ngrxLet="filter$; let filter">
    <div fxFlex="95">
      <app-filter-chips *ngIf="filter !== undefined" [filter]="filter"></app-filter-chips>
    </div>
  </ng-container>

  <div fxFlex="5">
    <button mat-icon-button
            *ngIf="!(filterSet$ | ngrxPush)"
            color="primary"
            (click)="openFilterDialog()"
            matTooltip="Встановити фільтр">
      <mat-icon>filter_list</mat-icon>
    </button>

    <button mat-icon-button
            *ngIf="filterSet$ | ngrxPush"
            color="warn"
            (click)="clearFilter()"
            matTooltip="Скинути фільтр">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

</div>

<div [hidden]="!(defensesNotZero$ | ngrxPush)">
  <table mat-table
         [dataSource]="defenses$">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell
          *matCellDef="let defense">
        <strong>{{ defense.defenseId }}</strong>
      </td>
    </ng-container>

    <ng-container matColumnDef="studentName">
      <th mat-header-cell *matHeaderCellDef>Здобувач</th>
      <td mat-cell
          *matCellDef="let defense">
        <strong>{{ defense.studentName }}</strong>
      </td>
    </ng-container>

    <ng-container matColumnDef="thesisTopic">
      <th mat-header-cell *matHeaderCellDef>Тема дисертації</th>
      <td mat-cell
          *matCellDef="let defense">
        {{ defense.thesisTopic }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hei">
      <th mat-header-cell *matHeaderCellDef>Заклад</th>
      <td mat-cell
          *matCellDef="let defense">
        {{ defense.op.hei.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="spec">
      <th mat-header-cell *matHeaderCellDef>Спеціальність</th>
      <td mat-cell
          *matCellDef="let defense">
        {{ defense.op.spec }}
      </td>
    </ng-container>

    <ng-container matColumnDef="defenseTime">
      <th mat-header-cell *matHeaderCellDef>Дата захисту</th>
      <td mat-cell
          *matCellDef="let defense">
        {{ defense.defenseTime !== undefined ? (defense.defenseTime | date) : '–' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>Стан</th>
      <td mat-cell
          *matCellDef="let defense">
        <mat-chip-list>
          <app-defense-state-chip [defenseState]="defense.state"></app-defense-state-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-column"></th>
      <td mat-cell
          *matCellDef="let defense"
          class="actions-column">
        <button mat-icon-button
                matTooltip="Переглянути деталі"
                [routerLink]="'/defense/' + defense.defenseId.toString()">
          <mat-icon color="primary">visibility</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>

  </table>

  <mat-paginator [length]="defensesTotalCount$ | ngrxPush"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 showFirstLastButtons="true"></mat-paginator>
</div>

<h4 [hidden]="defensesNotZero$ | ngrxPush">Схоже, нічого не знайдено...</h4>


