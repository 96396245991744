import { Component, Input } from '@angular/core';
import { EnumsService } from '../../services/enums/enums.service';

@Component({
  selector: 'app-defense-state-chip',
  templateUrl: './defense-state-chip.component.html',
  styleUrls: ['./defense-state-chip.component.css'],
})
export class DefenseStateChipComponent {
  @Input() defenseState: number;

  constructor(public enums: EnumsService) {}
}
