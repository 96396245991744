import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-representations',
  templateUrl: './representations.component.html',
  styleUrls: ['./representations.component.css'],
})
export class RepresentationsComponent {
  @Input() type: 'hei' | 'hei-short' | 'mon' | 'naqa';

  constructor() {}
}
