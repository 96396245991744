import { createAction } from '@ngrx/store';

export enum LoadingActionTypes {
  LOADING_ON = '[Loading] Loading On',
  LOADING_OFF = '[Loading] Loading Off',
  LOADING_ZERO = '[Loading] Loading Zero',
}

export const loadingOn = createAction(LoadingActionTypes.LOADING_ON);

export const loadingOff = createAction(LoadingActionTypes.LOADING_OFF);

export const loadingZero = createAction(LoadingActionTypes.LOADING_ZERO);
