import { EndUserKeyMedia, EndUserOwnerInfo } from '../../iit';

export interface SignState {
  keyMedias: EndUserKeyMedia[];
  currentPrivateKey: EndUserOwnerInfo | null;
}

export const initialSignState: SignState = {
  keyMedias: [],
  currentPrivateKey: null,
};
