import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Відкриття снекбару з визначеним повідомленням.
   * @param message Повідомлення на снекбарі.
   * @param isError Чи повідомлення має відображатися як помилка?
   */
  openSnackBar(message: string, isError: boolean = false): void {
    let options: MatSnackBarConfig = {};
    if (isError) options.panelClass = 'error-snackbar';

    this.snackBar.open(message, '', options);
  }
}
