import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

export type NameValueType = { name: string; value: any }[];

@Component({
  selector: 'app-name-value-table',
  templateUrl: './name-value-table.component.html',
  styleUrls: ['./name-value-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NameValueTableComponent {
  @Input() dataSource: NameValueType | Observable<NameValueType>;
  readonly columns = ['name', 'value'];

  constructor() {}
}
