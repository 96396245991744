import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, switchMap, tap } from 'rxjs';
import { ApiService } from '../../services/api/api.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { loadingOff, loadingOn } from '../../state/loading/loading.actions';
import { TMultipleEntitiesApiResponse } from '../../types/api.types';
import {
  DatatableState,
  FilterFields,
  FilterMap,
  FilterValue,
  PaginatorOptions,
} from '../../types/datatable.types';
import { valueIsArray, valueIsMoment, valueIsString } from '../utils';

export class DatatableStoreTemplate<
  T,
  F extends FilterFields,
> extends ComponentStore<DatatableState<T, F>> {
  readonly apiPath: string;

  constructor(
    apiPath: string,
    readonly api: ApiService,
    readonly store: Store<AppState>,
  ) {
    super({
      skip: 0,
      limit: 10,
      count: 0,
      entities: [],
    });
    this.apiPath = apiPath;
  }

  readonly setPaginator = this.effect(
    (options$: Observable<PaginatorOptions>) => {
      return options$.pipe(
        tap(() => this.store.dispatch(loadingOn())),
        tap((options) => this._setPaginator(options)),
        switchMap((options) =>
          this.api.getMultipleEntitiesWithPagination<T>(
            options.skip,
            options.limit,
            this.apiPath,
            this.transformToFilterString(this.get().filter),
          ),
        ),
        tapResponse(
          (response) => {
            this.retrievedEntities(response);
            this.store.dispatch(loadingOff());
          },
          () => {
            this.clearEntities();
            this.store.dispatch(loadingOff());
          },
        ),
      );
    },
  );

  readonly setFilter = this.effect((filter$: Observable<FilterMap<F>>) => {
    return filter$.pipe(
      tap(() => this.store.dispatch(loadingOn())),
      tap((filter) => this._setFilter(filter)),
      switchMap((filter) =>
        this.api.getMultipleEntitiesWithPagination<T>(
          this.get().skip,
          this.get().limit,
          this.apiPath,
          this.transformToFilterString(filter),
        ),
      ),
      tapResponse(
        (response) => {
          this.retrievedEntities(response);
          this.store.dispatch(loadingOff());
        },
        () => {
          this.clearEntities();
          this.store.dispatch(loadingOff());
        },
      ),
    );
  });

  readonly clearFilter = this.effect((filter$: Observable<void>) => {
    return filter$.pipe(
      tap(() => this.store.dispatch(loadingOn())),
      tap(() => this._clearFilter()),
      switchMap(() =>
        this.api.getMultipleEntitiesWithPagination<T>(
          this.get().skip,
          this.get().limit,
          this.apiPath,
        ),
      ),
      tapResponse(
        (response) => {
          this.retrievedEntities(response);
          this.store.dispatch(loadingOff());
        },
        () => {
          this.clearEntities();
          this.store.dispatch(loadingOff());
        },
      ),
    );
  });

  readonly _setPaginator = this.updater((state, options: PaginatorOptions) => ({
    ...state,
    ...options,
  }));

  readonly _setFilter = this.updater((state, filter: FilterMap<F>) => ({
    ...state,
    filter,
  }));

  readonly _clearFilter = this.updater((state) => ({
    ...state,
    filter: undefined,
  }));

  readonly retrievedEntities = this.updater(
    (state, response: TMultipleEntitiesApiResponse<T>) => ({
      ...state,
      ...response,
    }),
  );

  readonly clearEntities = this.updater((state) => ({
    ...state,
    count: 0,
    entities: [],
  }));

  entities(): Observable<T[]> {
    return this.select((state) => state.entities);
  }

  entitiesNotZero(): Observable<boolean> {
    return this.select((state) => state.count > 0);
  }

  entitiesTotalCount(): Observable<number> {
    return this.select((state) => state.count);
  }

  filter(): Observable<FilterMap<F> | undefined> {
    return this.select((state) => state.filter);
  }

  private transformToFilterString(
    filter: FilterMap<F> | undefined,
  ): string | undefined {
    if (filter == undefined) return undefined;

    return Array.from(filter.entries())
      .map((value) => this.serializeFilterCondition(value[0], value[1]))
      .join(';');
  }

  private serializeFilterCondition(field: F, value: FilterValue<F>): string {
    if (valueIsMoment(value)) {
      return `${field}:${value.format('YYYY-MM-DD')}`;
    } else if (valueIsArray(value)) {
      return `${field}:${value.join(',')}`;
    } else if (valueIsString(value)) {
      return `${field}:${value}`;
    } else {
      return `${field}:${value.toString()}`;
    }
  }
}
