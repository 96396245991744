import { EndUserSettings } from '../../iit';
import { environment } from '../../../environments/environment';

export const euSettings: EndUserSettings = {
  language: 'uk',
  encoding: 'utf-8',
  httpProxyServiceURL: environment.proxyUri,
  directAccess: true,
  CAs: 'assets/CAs.json',
  CACertificates: 'assets/CACertificates.p7b',
};
