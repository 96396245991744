<h3 mat-dialog-title>Фільтр</h3>

<form [formGroup]="allDefensesFilterForm">

  <!--Row 1-->
  <div fxLayout="row" fxLayoutAlign="start end">
    <mat-form-field>
      <mat-label>Текстовий пошук</mat-label>
      <input matInput formControlName="text" type="text" autocomplete="off">
      <mat-hint>Пошук відбувається за ID захисту, ПІБ здобувача, темою і ключових словах дисертації</mat-hint>
    </mat-form-field>
  </div>

  <!--Row 2-->
  <div fxLayout="row" fxLayoutAlign="start end">
    <mat-form-field>
      <mat-label>Пошук за назвою закладу освіти/наукової установи</mat-label>
      <input matInput formControlName="hei" type="text" autocomplete="off">
    </mat-form-field>
  </div>

  <!--Row 3-->
  <div fxLayout="row" fxLayoutAlign="start end">
    <mat-form-field>
      <mat-label>Стан захисту</mat-label>
      <mat-select formControlName="state" multiple>
        <mat-option *ngFor="let state of enums.DefenseStateMap | keyvalue"
                    [value]="state.key">
          {{ state.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--Row 4-->
  <div fxLayout="row" fxLayout.lt-md="column"
       fxLayoutAlign="start end" fxLayoutAlign.lt-md="start stretch">

    <div fxFlex="47" fxFlex.lt-md="auto">
      <mat-form-field>
        <mat-label>Галузь знань</mat-label>
        <mat-select formControlName="branches" multiple>
          <mat-option *ngFor="let branch of branches"
                      [value]="branch.slice(0, 2)">
            {{ branch }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="3" [fxHide.lt-md]="true"></div>

    <div fxFlex="50" fxFlex.lt-md="auto">
      <mat-form-field>
        <mat-label>Спеціальність</mat-label>
        <mat-select formControlName="specs" multiple>
          <mat-option *ngFor="let spec of specs"
                      [value]="spec.slice(0, 3)">
            {{ spec }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--Row 5-->
  <div fxLayout="row" fxLayout.lt-md="column"
       fxLayoutAlign="start end" fxLayoutAlign.lt-md="start stretch">

    <div fxFlex="47" fxFlex.lt-md="auto">
      <mat-form-field>
        <mat-label>Дата захисту</mat-label>
        <mat-date-range-input [rangePicker]="defenseDatePicker">
          <input matStartDate matInput formControlName="defenseDateStart" readonly>
          <input matEndDate matInput formControlName="defenseDateEnd" readonly>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="defenseDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #defenseDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div fxFlex="3" [fxHide.lt-md]="true"></div>

    <div fxFlex="50" fxFlex.lt-md="auto">
      <mat-form-field>
        <mat-label>Дата останнього рішення щодо разової ради</mat-label>
        <mat-date-range-input [rangePicker]="svrLastDecisionDatePicker">
          <input matStartDate matInput formControlName="svrLastDecisionDateStart" readonly>
          <input matEndDate matInput formControlName="svrLastDecisionDateEnd" readonly>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="svrLastDecisionDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #svrLastDecisionDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start end">
    <mat-checkbox formControlName="complaintsPresent">Захисти з повідомленнями про невідповідність</mat-checkbox>
  </div>

</form>

<mat-dialog-actions>
  <button mat-button
          [mat-dialog-close]="null">Скасувати</button>
  <button mat-raised-button
          [disabled]="!(filterIsValid$ | ngrxPush)"
          color="primary"
          [mat-dialog-close]="allDefensesFilterForm.value">Застосувати</button>
</mat-dialog-actions>
