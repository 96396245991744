import { Component, Input } from '@angular/core';
import { EnumsService, TUsedMap } from '../../services/enums/enums.service';
import { Moment } from 'moment';
import { FilterFields, FilterMap } from '../../types/datatable.types';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.css'],
})
export class FilterChipsComponent {
  @Input() filter: FilterMap<FilterFields>;

  constructor(public enums: EnumsService) {}

  transformArray(map: TUsedMap, value: any[]): string {
    return value.map((value) => this.enums.queryMap(map, value)).join(' | ');
  }

  transformMoment(value: Moment): string {
    return value.format('DD.MM.YYYY');
  }
}
