import { createReducer, on } from '@ngrx/store';
import { initialSignState } from './sign.state';
import {
  clearedPrivateKey,
  readedPrivateKey,
  retrievedKeyMedias,
} from './sign.actions';

export const signReducer = createReducer(
  initialSignState,
  on(retrievedKeyMedias, (state, { keyMedias }) => {
    keyMedias = keyMedias.filter((value) => value.typeIndex !== 0);
    return { ...state, keyMedias };
  }),
  on(readedPrivateKey, (state, { currentPrivateKey }) => ({
    ...state,
    currentPrivateKey,
  })),
  on(clearedPrivateKey, (state) => ({ ...state, currentPrivateKey: null })),
);
