import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SnackbarService } from './services/snackbar/snackbar.service';
import { Store } from '@ngrx/store';
import { loadingZero } from './state/loading/loading.actions';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  override handleError(error: any): void {
    super.handleError(error);
    const snackbar = this.injector.get(SnackbarService);
    const store = this.injector.get(Store);
    const msg =
      error.rejection?.message ?? error.error?.message ?? error.message;
    snackbar.openSnackBar(msg, true);
    store.dispatch(loadingZero());
  }
}
