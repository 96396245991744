import { Component, Input } from '@angular/core';
import { IDefenseApiResponse } from '../../interfaces/common/defense.interface';
import * as moment from 'moment';
import { NameValueType } from '../name-value-table/name-value-table.component';

@Component({
  selector: 'app-short-defense-display',
  templateUrl: './short-defense-display.component.html',
  styleUrls: ['./short-defense-display.component.css'],
})
export class ShortDefenseDisplayComponent {
  @Input() defense: IDefenseApiResponse;
  readonly columns = ['name', 'value'];

  constructor() {}

  transformDefenseToRowArray(defense: IDefenseApiResponse): NameValueType {
    return [
      { name: 'ID захисту', value: defense.defenseId },
      { name: 'ПІБ здобувача', value: defense.studentName },
      { name: 'Тема дисертації', value: defense.thesisTopic },
      {
        name: 'Заклад освіти, освітня програма',
        value: `${defense.op.hei.name}, ОП ${defense.op.opId} ${defense.op.name} (${defense.op.spec})`,
      },
      {
        name: 'Дата і час захисту',
        value:
          defense.defenseTime !== undefined
            ? moment(defense.defenseTime).format('DD.MM.YYYY HH:mm')
            : 'дані відсутні',
      },
    ];
  }
}
