export const version = '1.0.7';

export const supportEmail = 'support@naqa.gov.ua';

export const oldSpec = [
  '01.01.01 Математичний аналіз',
  '01.01.02 Диференціальні рівняння',
  '01.01.03 Математична фізика',
  '01.01.04 Геометрія та топологія',
  '01.01.05 Теорія ймовірностей і математична статистика',
  '01.01.06 Алгебра та теорія чисел',
  '01.01.07 Обчислювальна математика',
  '01.01.08 Математична логіка, теорія алгоритмів і дискретна математика',
  '01.01.09 Варіаційне числення та теорія оптимального керування',
  '01.01.10 Дослідження операцій та теорія ігор',
  '01.02.01 Теоретична механіка',
  '01.02.04 Механіка деформівного твердого тіла',
  '01.02.05 Механіка рідини, газу та плазми',
  '01.03.01 Астрометрія і небесна механіка',
  '01.03.02 Астрофізика, радіоастрономія',
  '01.03.03 Геліофізика і фізика Сонячної системи',
  '01.04.01 Фізика приладів, елементів і систем',
  '01.04.02 Теоретична фізика',
  '01.04.03 Радіофізика',
  '01.04.04 Фізична електроніка',
  '01.04.05 Оптика, лазерна фізика',
  '01.04.06 Акустика',
  '01.04.07 Фізика твердого тіла',
  '01.04.08 Фізика плазми',
  '01.04.09 Фізика низьких температур',
  '01.04.10 Фізика напівпровідників і діелектриків',
  '01.04.11 Магнетизм',
  '01.04.13 Фізика металів',
  '01.04.14 Теплофізика та молекулярна фізика',
  '01.04.15 Фізика молекулярних та рідких кристалів',
  '01.04.16 Фізика ядра, елементарних частинок і високих енергій',
  '01.04.17 Хімічна фізика, фізика горіння та вибуху',
  '01.04.18 Фізика і хімія поверхні',
  '01.04.19 Фізика полімерів',
  '01.04.20 Фізика пучків заряджених частинок',
  '01.04.21 Радіаційна фізика та ядерна безпека',
  '01.04.22 Надпровідність',
  '01.04.24 Фізика колоїдних систем',
  '01.05.01 Теоретичні основи інформатики та кібернетики',
  '01.05.02 Математичне моделювання та обчислювальні методи',
  '01.05.03 Математичне та програмне забезпечення обчислювальних машин і систем',
  '01.05.04 Системний аналіз і теорія оптимальних рішень',
  '02.00.01 Неорганічна хімія',
  '02.00.02 Аналітична хімія',
  '02.00.03 Органічна хімія',
  '02.00.04 Фізична хімія',
  '02.00.05 Електрохімія',
  '02.00.06 Хімія високомолекулярних сполук',
  '02.00.08 Хімія елементоорганічних сполук',
  '02.00.09 Хімія високих енергій',
  '02.00.10 Біоорганічна хімія',
  '02.00.11 Колоїдна хімія',
  '02.00.13 Нафтохімія і вуглехімія',
  '02.00.15 Хімічна кінетика і каталіз',
  '02.00.19 Хімія високочистих речовин',
  '02.00.21 Хімія твердого тіла',
  '03.00.01 Радіобіологія',
  '03.00.02 Біофізика',
  '03.00.03 Молекулярна біологія',
  '03.00.04 Біохімія',
  '03.00.05 Ботаніка',
  '03.00.06 Вірусологія',
  '03.00.07 Мікробіологія',
  '03.00.08 Зоологія',
  '03.00.09 Ентомологія',
  '03.00.09 Імунологія',
  '03.00.10 Іхтіологія',
  '03.00.11 Цитологія, клітинна біологія, гістологія',
  '03.00.12 Фізіологія рослин',
  '03.00.13 Фізіологія людини і тварин',
  '03.00.14 Антропологія',
  '03.00.14 Біологія розвитку',
  '03.00.15 Генетика',
  '03.00.16 Екологія',
  '03.00.17 Гідробіологія',
  '03.00.18 Паразитологія, гельмінтологія',
  '03.00.18 Ґрунтознавство',
  '03.00.19 Кріобіологія та кріомедицина',
  '03.00.19 Кріобіологія',
  '03.00.20 Біотехнологія',
  '03.00.21 Мікологія',
  '03.00.22 Молекулярна генетика',
  '03.00.24 Ентомологія',
  '03.00.25 Паразитологія, гельмінтологія',
  '03.00.26 Молекулярна генетика ',
  '03.00.30 Біологія розвитку',
  '03.00.30 Біомеханіка',
  '04.00.01 Загальна та регіональна геологія',
  '04.00.02 Геохімія',
  '04.00.04 Геотектоніка',
  '04.00.05 Геологічна інформатика',
  '04.00.06 Гідрогеологія',
  '04.00.07 Інженерна геологія',
  '04.00.08 Петрологія',
  '04.00.09 Палеонтологія і стратиграфія',
  '04.00.10 Геологія океанів і морів',
  '04.00.11 Геологія металевих і неметалевих корисних копалин',
  '04.00.16 Геологія твердих горючих копалин',
  '04.00.17 Геологія нафти і газу',
  '04.00.19 Економічна геологія',
  '04.00.20 Мінералогія, кристалографія',
  '04.00.21 Літологія',
  '04.00.22 Геофізика',
  '05.01.01 Прикладна геометрія, інженерна графіка',
  '05.01.02 Стандартизація та сертифікація',
  '05.01.02 Стандартизація, сертифікація та метрологічне забезпечення',
  '05.01.03 Технічна естетика',
  '05.01.04 Ергономіка',
  '05.02.01 Матеріалознавство',
  '05.02.02 Машинознавство',
  '05.02.03 Системи приводів',
  '05.02.04 Тертя та зношування в машинах',
  '05.02.08 Технологія машинобудування',
  '05.02.09 Динаміка та міцність машин',
  '05.02.10 Діагностика матеріалів і конструкцій',
  '05.03.01 Процеси механічної обробки, верстати та інструменти',
  '05.03.05 Процеси та машини обробки тиском',
  '05.03.06 Зварювання та споріднені процеси і технології',
  '05.03.07 Процеси фізико-технічної обробки',
  '05.04.01 Котли, парогенератори та камери згоряння',
  '05.04.02 Теплові двигуни',
  '05.04.03 Холодильна та кріогення техніка, системи кондиціювання',
  '05.04.06 Вакуумна та компресорна техніка',
  '05.04.11 Атомне реакторобудування',
  '05.04.12 Турбомашини та турбоустановки',
  '05.04.13 Гідравлічні машини та гідропневмоагрегати',
  '05.04.15 Суднові енергетичні установки',
  '05.05.01 Машини і процеси поліграфічного виробництва',
  '05.05.02 Машини для виробництва будівельних матеріалів і конструкцій',
  '05.05.03 Двигуни та енергетичні установки',
  '05.05.04 Машини для земляних, дорожніх і лісотехнічних робіт',
  '05.05.05 Піднімально-транспортні машини',
  '05.05.06 Гірничі машини',
  '05.05.08 Машини для металургійного виробництва',
  '05.05.09 Машини харчової, мікробіологічної та фармацевтичної промисловості',
  '05.05.10 Машини легкої промисловості',
  '05.05.11 Сількогосподарські машини',
  '05.05.11 Машини і засоби механізації сільськогосподарського виробництва',
  '05.05.12 Машини нафтової та газової промисловості',
  '05.05.13 Машини та апарати хімічних виробництв',
  '05.05.14 Холодильна, вакуумна та компресорна техніка, системи кондиціювання',
  '05.05.16 Турбомашини та турбоустановки',
  '05.05.17 Гідравлічні машини та гідропневмоагрегати',
  '05.05.18 Машини та обладнання промислового рибальства',
  '05.07.01 Аеродинаміка та газодинаміка літальних апаратів',
  '05.07.02 Проектування, виробництво та випробування літальних апаратів',
  '05.07.06 Наземні комплекси, стартове обладнання',
  '05.07.12 Дистанційні аерокосмічні дослідження',
  '05.07.14 Авіаційно-космічні тренажери',
  '05.08.01 Теорія корабля',
  '05.08.03 Конструювання та будування суден',
  '05.09.01 Електричні машини й апарати',
  '05.09.03 Електротехнічні комплекси та системи',
  '05.09.05 Теоретична електротехніка',
  '05.09.07 Світлотехніка та джерела світла',
  '05.09.08 Прикладна акустика та звукотехніка',
  '05.09.12 Напівпровідникові перетворювачі електроенергії',
  '05.09.13 Техніка сильних електричних та магнітних полів',
  '05.11.01 Прилади та методи вимірювання механічних величин',
  '05.11.03 Гіроскопи та навігаційні системи',
  '05.11.04 Прилади та методи вимірювання теплових величин',
  '05.11.05 Прилади та методи вимірювання електричних та магнітних величин',
  '05.11.07 Оптичні прилади та системи',
  '05.11.08 Радіовимірювальні прилади',
  '05.11.13 Прилади і методи контролю та визначення складу речовин',
  '05.11.17 Біологічні та медичні прилади і системи',
  '05.12.02 Телекомунікаційні системи та мережі',
  '05.12.07 Антени та пристрої мікрохвильової техніки',
  '05.12.13 Радіотехнічні пристрої та засоби телекомунікацій',
  '05.12.17 Радіотехнічні та телевізійні системи',
  '05.12.20 Оптоелектронні системи',
  '05.13.03 Системи та процеси керування',
  "05.13.05 Комп'ютерні системи та компоненти",
  '05.13.06 Інформаційні технології',
  '05.13.07 Автоматизація процесів керування',
  '05.13.09 Медична та біологічна інформатика і кібернетика',
  '05.13.12 Системи автоматизації проектувальних робіт',
  '05.13.21 Системи захисту інформації',
  '05.13.22 Управління проектами і програмами',
  '05.13.23 Системи та засоби штучного інтелекту',
  '05.14.01 Енергетичні системи та комплекси',
  '05.14.02 Електричні станції, мережі і системи',
  '05.14.06 Технічна теплофізика та промислова теплоенергетика',
  '05.14.08 Перетворювання відновлюваних видів енергії',
  '05.14.14 Теплові та ядерні енергоустановки',
  '05.15.01 Маркшейдерія',
  '05.15.02 Підземна розробка родовищ корисних копалин',
  '05.15.03 Відкрита розробка родовищ корисних копалин',
  '05.15.04 Шахтне та підземне будівництво',
  '05.15.06 Розробка нафтових та газових родовищ',
  '05.15.08 Збагачення корисних копалин',
  '05.15.09 Геотехнічна і гірнича механіка',
  '05.15.10 Буріння свердловин',
  '05.15.12 Розробка морських родовищ корисних копалин',
  '05.15.13 Трубопровідний транспорт, нафтогазосховища',
  '05.16.01 Металознавство та термічна обробка металів',
  '05.16.02 Металургія чорних і кольорових металів та спеціальних сплавів',
  '05.16.04 Ливарне виробництво',
  '05.16.06 Порошкова металургія та композиційні матеріали',
  '05.17.01 Технологія неорганічних речовин',
  '05.17.03 Технічна електрохімія',
  '05.17.04 Технологія продуктів органічного синтезу',
  '05.17.06 Технологія полімерних і композиційних матеріалів',
  '05.17.07 Хімічна технологія палива і паливно-мастильних матеріалів',
  '05.17.08 Процеси та обладнання хімічної технології',
  '05.17.11 Технологія тугоплавких неметалічних матеріалів',
  '05.17.14 Хімічний опір матеріалів та захист від корозії',
  '05.17.15 Технологія хімічних волокон',
  '05.17.18 Мембрани та мембранна технологія',
  '05.17.21 Технологія водоочищення',
  '05.18.01 Технологія хлібопекарських продуктів, кондитерських виробів та харчових концентратів',
  "05.18.02 Технологія зернових, бобових, круп'яних продуктів і комбікормів, олійних і луб'яних культур",
  "05.18.04 Технологія м'ясних, молочних продуктів і продуктів з гідробіонтів",
  '05.18.05 Технологія цукристих речовин та продуктів бродіння',
  '05.18.06 Технологія жирів, ефірних масел і парфумерно-косметичних продуктів',
  '05.18.08 Товарознавство непродовольчих товарів',
  '05.18.12 Процеси та обладнання харчових, мікробіологічних та фармацевтичних виробництв',
  '05.18.13 Технологія консервованих і охолоджених харчових продуктів',
  '05.18.15 Товарознавство харчових продуктів',
  '05.18.16 Технологія харчової продукції',
  '05.18.18 Технологія взуття, шкіряних виробів і хутра',
  '05.18.19 Технологія текстильних матеріалів, швейних і трикотажних виробів',
  '05.22.01 Транспортні системи',
  '05.22.02 Автомобілі та трактори',
  '05.22.06 Залізнична колія',
  '05.22.07 Рухомий склад залізниць та тяга поїздів',
  '05.22.09 Електротранспорт',
  '05.22.11 Автомобільні шляхи та аеродроми',
  '05.22.12 Промисловий транспорт',
  '05.22.13 Навігація та управління рухом',
  '05.22.20 Експлуатація та ремонт засобів транспорту',
  '05.23.01 Будівельні конструкції, будівлі та споруди',
  '05.23.02 Основи і фундаменти',
  '05.23.03 Вентиляція, освітлення та теплогазопостачання',
  '05.23.04 Водопостачання, каналізація',
  '05.23.05 Будівельні матеріали та вироби',
  '05.23.06 Технологія деревообробки, виготовлення меблів та виробів з деревини',
  '05.23.08 Технологія та організація промислового та цивільного будівництва',
  '05.23.16 Гідравліка та інженерна гідрологія',
  '05.23.17 Будівельна механіка',
  '05.23.20 Містобудування та територіальне планування',
  '05.24.01 Геодезія, фотограмметрія та картографія',
  '05.24.04 Кадастр та моніторинг земель',
  '05.26.01 Охорона праці',
  '05.27.01 Твердотільна електроніка',
  '05.27.02 Вакуумна, плазмова та квантова електроніка',
  '05.27.06 Технологія, обладнання та виробництво електронної техніки',
  '06.01.01 Загальне землеробство',
  '06.01.02 Сільськогосподарські меліорації',
  '06.01.03 Агрогрунтознавство і агрофізика',
  '06.01.04 Агрохімія',
  '06.01.05 Селекція і насінництво',
  '06.01.06 Овочівництво',
  '06.01.07 Плодівництво',
  '06.01.08 Виноградарство',
  '06.01.09 Рослинництво',
  '06.01.10 Субтропічні культури',
  '06.01.11 Фітопатологія',
  '06.01.12 Кормовиробництво і луківництво',
  '06.01.13 Гербологія',
  '06.01.15 Первинна обробка продуктів рослинництва',
  '06.02.01 Розведення та селекція тварин',
  '06.02.02 Годівля тварин і технологія кормів',
  '06.02.03 Рибництво',
  '06.02.04 Технологія виробництва продуктів тваринництва',
  '06.03.01 Лісові культури та фітомеліорація',
  '06.03.02 Лісовпорядкування і лісова таксація',
  '06.03.03 Лісознавство і лісівництво',
  '07.00.01 Історія України',
  '07.00.02 Всесвітня історія',
  '07.00.04 Археологія',
  '07.00.05 Етнологія',
  '07.00.06 Історіографія, джерелознавство та спеціальні історичні дисципліни',
  '07.00.07 Історія науки й техніки',
  '07.00.09 Антропологія',
  '08.00.01 Економічна теорія та історія економічної думки',
  '08.00.02 Світове господарство і міжнародні економічні відносини',
  '08.00.03 Економіка та управління національним господарством',
  '08.00.04 Економіка та управління підприємствами (за видами економічної діяльності)',
  '08.00.05 Розвиток продуктивних сил і регіональна економіка',
  '08.00.06 Економіка природокористування та охорони навколишнього середовища',
  '08.00.07 Демографія, економіка праці, соціальна економіка і політика',
  '08.00.08 Гроші, фінанси і кредит',
  '08.00.09 Бухгалтерський облік, аналіз та аудит (за видами економічної діяльності)',
  '08.00.10 Статистика',
  '08.00.11 Математичні методи, моделі та інформаційні технології в економіці',
  '09.00.01 Онтологія, гносеологія, феноменологія',
  '09.00.02 Діалектика і методологія пізнання',
  '09.00.03 Соціальна філософія та філософія історії',
  '09.00.04 Філософська антропологія, філософія культури',
  '09.00.05 Історія філософії',
  '09.00.06 Логіка',
  '09.00.07 Етика',
  '09.00.08 Естетика',
  '09.00.09 Філософія науки',
  '09.00.10 Філософія освіти',
  '09.00.11 Релігієзнавство',
  '09.00.12 Українознавство',
  "09.00.14 Богослов'я",
  '10.01.01 Українська література',
  '10.01.02 Російська література',
  "10.01.03 Література слов'янських народів",
  '10.01.04 Література зарубіжних країн',
  '10.01.05 Порівняльне літературознавство',
  '10.01.06 Теорія літератури',
  '10.01.07 Фольклористика',
  '10.01.09 Літературне джерелознавство і текстологія',
  '10.01.10 Кримськотатарська література',
  '10.02.01 Українська мова',
  '10.02.02 Російська мова',
  "10.02.03 Слов'янські мови",
  '10.02.04 Германські мови',
  '10.02.05 Романські мови',
  '10.02.06 Балтійські мови',
  '10.02.07 Індоіранські мови',
  '10.02.08 Тюркські мови',
  '10.02.09 Фінно-угорські та самодійські мови',
  '10.02.10 Іберійсько-кавказькі мови',
  '10.02.11 Монгольські мови',
  '10.02.12 Семітські мови',
  '10.02.13 Мови народів Азії, Африки, аборигенних народів Америки та Австралії',
  '10.02.14 Класичні мови. Окремі індоєвропейські мови',
  '10.02.15 Загальне мовознавство',
  '10.02.16 Перекладознавство',
  '10.02.17 Порівняльно-історичне і типологічне мовознавство',
  '10.02.21 Структурна, прикладна та математична лінгвістика',
  '11.00.01 Фізична географія, геофізика і геохімія ландшафтів',
  '11.00.02 Економічна та соціальна географія',
  '11.00.04 Геоморфологія та палеогеографія',
  '11.00.05 Біогеографія та географія ґрунтів',
  '11.00.07 Гідрологія суші, водні ресурси, гідрохімія',
  '11.00.08 Океанологія',
  '11.00.09 Метеорологія, кліматологія, агрометеорологія',
  '11.00.11 Конструктивна географія і раціональне використання природних ресурсів',
  '11.00.12 Географічна картографія',
  '12.00.01 Теорія та історія держави і права; історія політичних і правових учень',
  '12.00.02 Конституційне право; муніципальне право',
  '12.00.03 Цивільне право і цивільний процес; сімейне право; міжнародне приватне право',
  '12.00.04 Господарське право, господарсько-процесуальне право',
  '12.00.05 Трудове право; право соціального забезпечення',
  '12.00.06 Земельне право; аграрне право; екологічне право; природоресурсне право',
  '12.00.07 Адміністративне право і процес; фінансове право; інформаційне право',
  '12.00.08 Кримінальне право та кримінологія; кримінально-виконавче право',
  '12.00.09 Кримінальний процес та криміналістика; судова експертиза; оперативно-розшукова діяльність',
  '12.00.10 Судоустрій; прокуратура та адвокатура',
  '12.00.11 Міжнародне право',
  '12.00.12 Філософія права',
  '13.00.01 Загальна педагогіка та історія педагогіки',
  '13.00.02 Теорія та методика навчання (з галузей знань)',
  '13.00.03 Корекційна педагогіка',
  '13.00.04 Теорія і методика професійної освіти',
  '13.00.05 Соціальна педагогіка',
  '13.00.06 Теорія і методика управління освітою',
  '13.00.07 Теорія і методика виховання',
  '13.00.08 Дошкільна педагогіка',
  '13.00.09 Теорія навчання',
  '13.00.10 Інформаційно-комунікаційні технології в освіті',
  '14.01.01 Акушерство та гінекологія',
  '14.01.02 Внутрішні хвороби',
  '14.01.03 Хірургія',
  '14.01.04 Серцево-судинна хірургія',
  '14.01.05 Нейрохірургія',
  '14.01.06 Урологія',
  '14.01.07 Онкологія',
  '14.01.08 Трансплантологія та штучні органи',
  '14.01.09 Дитяча хірургія',
  '14.01.10 Педіатрія',
  '14.01.11 Кардіологія',
  '14.01.12 Ревматологія',
  '14.01.13 Інфекційні хвороби',
  '14.01.14 Ендокринологія',
  '14.01.15 Нервові хвороби',
  '14.01.16 Психіатрія',
  '14.01.17 Наркологія',
  '14.01.18 Офтальмологія',
  '14.01.19 Оториноларингологія',
  '14.01.20 Шкірні та венеричні хвороби',
  '14.01.21 Травматологія та ортопедія',
  '14.01.22 Стоматологія',
  '14.01.23 Променева діагностика та променева терапія',
  '14.01.24 Лікувальна фізкультура та спортивна медицина',
  '14.01.25 Судова медицина',
  '14.01.26 Фтизіатрія',
  '14.01.27 Пульмонологія',
  '14.01.28 Клінічна фармакологія',
  '14.01.29 Клінічна алергологія',
  '14.01.30 Анестезіологія та інтенсивна терапія',
  '14.01.31 Гематологія та трансфузіологія',
  '14.01.32 Медична біохімія',
  '14.01.33 Медична реабілітація, фізіотерапія та курортологія',
  '14.01.34 Космічна медицина',
  '14.01.35 Кріомедицина',
  '14.01.36 Гастроентерологія',
  '14.01.37 Нефрологія',
  '14.01.38 Загальна практика - сімейна медицина',
  '14.01.39 Клінічна лабораторна діагностика',
  '14.02.01 Гігієна та професійна патологія',
  '14.02.02 Епідеміологія',
  '14.02.03 Соціальна медицина',
  '14.03.01 Нормальна анатомія',
  '14.03.02 Патологічна анатомія',
  '14.03.03 Нормальна фізіологія',
  '14.03.04 Патологічна фізіологія',
  '14.03.05 Фармакологія',
  '14.03.06 Токсикологія',
  '14.03.07 Фізіологічно активні сполуки',
  '14.03.08 Імунологія та алергологія',
  '14.03.09 Гістологія, цитологія, ембріологія',
  '14.03.10 Біомеханіка',
  '14.03.11 Медична та біологічна інформатика і кібернетика',
  '15.00.01 Технологія ліків, організація фармацевтичної справи та судова фармація',
  '15.00.02 Фармацевтична хімія та фармакогнозія',
  '15.00.03 Стандартизація та організація виробництва лікарських засобів',
  '16.00.01 Діагностика і терапія тварин',
  '16.00.02 Патологія, онкологія і морфологія тварин',
  '16.00.03 Ветеринарна мікробіологія, епізоотологія, інфекційні хвороби та імунологія',
  '16.00.04 Ветеринарна фармакологія та токсикологія',
  '16.00.05 Ветеринарна хірургія',
  '16.00.06 Гігієна тварин та ветеринарна санітарія',
  '16.00.07 Ветеринарне акушерство',
  '16.00.09 Ветеринарно-санітарна експертиза',
  '16.00.10 Ентомологія',
  '16.00.11 Паразитологія',
  '17.00.02 Театральне мистецтво',
  '17.00.03 Музичне мистецтво',
  '17.00.04 Кіномистецтво. Телебачення',
  '17.00.05 Образотворче мистецтво',
  '17.00.06 Декоративне і прикладне мистецтво',
  '17.00.07 Дизайн',
  "18.00.01 Теорія архітектури, реставрація пам'яток архітектури",
  '18.00.02 Архітектура будівель та споруд',
  '18.00.04 Містобудування та ландшафтна архітектура',
  '19.00.01 Загальна психологія, історія психології',
  '19.00.02 Психофізіологія',
  '19.00.03 Психологія праці; інженерна психологія',
  '19.00.04 Медична психологія',
  '19.00.05 Соціальна психологія; психологія соціальної роботи',
  '19.00.06 Юридична психологія',
  '19.00.07 Педагогічна та вікова психологія',
  '19.00.08 Спеціальна психологія',
  '19.00.09 Психологія діяльності в особливих умовах',
  '19.00.10 Організаційна психологія; економічна психологія',
  '19.00.11 Політична психологія',
  '20.01.01 Воєнне мистецтво',
  '20.01.05 Будівництво Збройних Сил',
  '20.01.07 Оборонна економіка',
  '20.01.08 Тил Збройних Сил',
  '20.01.10 Розвідка та іноземні армії',
  '20.01.12 Радіоелектронна боротьба, способи та засоби',
  '20.02.01 Військова географія',
  '20.02.05 Інженерне обладнання театрів воєнних дій',
  '20.02.11 Засоби та методи військової навігації',
  "20.02.12 Військова кібернетика, системи управління та зв'язок",
  '20.02.14 Озброєння і військова техніка',
  '20.02.15 Гідроаеродинаміка, динаміка руху та маневрування бойових засобів',
  '20.02.20 Теорія стрільби',
  '20.02.22 Військова історія',
  '20.02.23 Засоби захисту від зброї масового ураження',
  '21.01.01 Основи національної безпеки держави',
  '21.02.01 Воєнна безпека держави',
  '21.02.02 Охорона державного кордону',
  '21.02.03 Цивільний захист',
  '21.03.01 Гуманітарна і політична безпека держави',
  '21.03.02 Регіональна безпека держави',
  '21.03.03 Геополітика',
  '21.04.01 Економічна безпека держави',
  "21.04.02 Економічна безпека суб'єктів господарської діяльності",
  '21.05.01 Інформаційна безпека держави',
  '21.06.01 Екологічна безпека',
  '21.06.02 Пожежна безпека',
  '21.07.01 Забезпечення державної безпеки України',
  '21.07.02 Розвідувальна діяльність органів державної безпеки',
  '21.07.03 Кадри органів та військ державної безпеки',
  '21.07.04 Оперативно-розшукова діяльність',
  '21.07.05 Службово-бойова діяльність сил охорони правопорядку',
  '21.08.01 Іноземні держави та їхні потенціали',
  '22.00.01 Теорія та історія соціології',
  '22.00.02 Методологія та методи соціологічних досліджень',
  '22.00.03 Соціальні структури та соціальні відносини',
  '22.00.04 Спеціальні та галузеві соціології',
  '23.00.01 Теорія та історія політичної науки',
  '23.00.02 Політичні інститути та процеси',
  '23.00.03 Політична культура та ідеологія',
  '23.00.04 Політичні проблеми міжнародних систем та глобального розвитку',
  '23.00.05 Етнополітологія та етнодержавознавство',
  '24.00.01 Олімпійський і професійний спорт',
  '24.00.02 Фізична культура, фізичне виховання різних груп населення',
  '24.00.03 Фізична реабілітація',
  '25.00.01 Теорія та історія державного управління',
  '25.00.02 Механізми державного управління',
  '25.00.03 Державна служба',
  '25.00.04 Місцеве самоврядування',
  '25.00.05 Державне управління у сфері державної безпеки та охорони громадського порядку',
  '26.00.01 Теорія та історія культури',
  "26.00.02 Світова культура і міжнародні культурні зв'язки",
  '26.00.04 Українська культура',
  "26.00.05 Музеєзнавство. Пам'яткознавство",
  '26.00.06 Прикладна культурологія. Культурні практики',
  '27.00.01 Теорія та історія соціальних комунікацій',
  '27.00.02 Документознавство, архівознавство',
  '27.00.03 Книгознавство, бібліотекознавство, бібліографознавство',
  '27.00.04 Теорія та історія журналістики',
  '27.00.05 Теорія та історія видавничої справи та редагування',
  '27.00.06 Прикладні соціально-комунікаційні технології',
  '27.00.07 Соціальна інформатика',
];

export const newSpec = [
  '011 Освітні, педагогічні науки',
  '012 Дошкільна освіта',
  '013 Початкова освіта',
  '014 Середня освіта (за предметними спеціальностями)',
  '015 Професійна освіта (за спеціалізаціями)',
  '016 Спеціальна освіта',
  '017 Фізична культура і спорт',
  '021 Аудіовізуальне мистецтво та виробництво',
  '022 Дизайн',
  '023 Образотворче мистецтво, декоративне мистецтво, реставрація',
  '024 Хореографія',
  '025 Музичне мистецтво',
  '026 Сценічне мистецтво',
  '027 Музеєзнавство, пам’яткознавство',
  '028 Менеджмент соціокультурної діяльності',
  '029 Інформаційна, бібліотечна та архівна справа',
  '031 Релігієзнавство',
  '032 Історія та археологія',
  '033 Філософія',
  '034 Культурологія',
  '035 Філологія',
  '041 Богослов’я',
  '051 Економіка',
  '052 Політологія',
  '053 Психологія',
  '054 Соціологія',
  '061 Журналістика',
  '071 Облік і оподаткування',
  '072 Фінанси, банківська справа та страхування',
  '073 Менеджмент',
  '075 Маркетинг',
  '076 Підприємництво, торгівля та біржова діяльність',
  '081 Право',
  '091 Біологія',
  '101 Екологія',
  '102 Хімія',
  '103 Науки про Землю*',
  '104 Фізика та астрономія',
  '105 Прикладна фізика та наноматеріали',
  '106 Географія',
  '111 Математика',
  '112 Статистика',
  '113 Прикладна математика',
  '121 Інженерія програмного забезпечення',
  '122 Комп’ютерні науки',
  '123 Комп’ютерна інженерія',
  '124 Системний аналіз',
  '125 Кібербезпека',
  '126 Інформаційні системи та технології',
  '131 Прикладна механіка',
  '132 Матеріалознавство',
  '133 Галузеве машинобудування',
  '134 Авіаційна та ракетно-космічна техніка',
  '135 Суднобудування',
  '136 Металургія',
  '141 Електроенергетика, електротехніка та електромеханіка',
  '142 Енергетичне машинобудування',
  '143 Атомна енергетика',
  '144 Теплоенергетика',
  '145 Гідроенергетика',
  '151 Автоматизація та комп’ютерно-інтегровані технології',
  '152 Метрологія та інформаційно-вимірювальна техніка',
  '153 Мікро- та наносистемна техніка',
  '161 Хімічні технології та інженерія',
  '162 Біотехнології та біоінженерія',
  '163 Біомедична інженерія',
  '171 Електроніка',
  '172 Телекомунікації та радіотехніка',
  '173 Авіоніка',
  '181 Харчові технології',
  '182 Технології легкої промисловості',
  '183 Технології захисту навколишнього середовища',
  '184 Гірництво',
  '185 Нафтогазова інженерія та технології',
  '186 Видавництво та поліграфія',
  '187 Деревообробні та меблеві технології',
  '191 Архітектура та містобудування',
  '192 Будівництво та цивільна інженерія',
  '193 Геодезія та землеустрій',
  '194 Гідротехнічне будівництво, водна інженерія та водні технології',
  '201 Агрономія',
  '202 Захист і карантин рослин',
  '203 Садівництво та виноградарство',
  '204 Технологія виробництва і переробки продукції тваринництва',
  '205 Лісове господарство',
  '206 Садово-паркове господарство',
  '207 Водні біоресурси та аквакультура',
  '208 Агроінженерія',
  '211 Ветеринарна медицина',
  '212 Ветеринарна гігієна, санітарія і експертиза',
  '221 Стоматологія',
  '222 Медицина',
  '223 Медсестринство',
  '224 Технології медичної діагностики та лікування',
  '225 Медична психологія',
  '226 Фармація, промислова фармація',
  '227 Фізична терапія, ерготерапія',
  '228 Педіатрія',
  '229 Громадське здоров’я',
  '231 Соціальна робота',
  '232 Соціальне забезпечення',
  '241 Готельно-ресторанна справа',
  '242 Туризм',
  '251 Державна безпека',
  '252 Безпека державного кордону',
  '253 Військове управління (за видами збройних сил)',
  '254 Забезпечення військ (сил)',
  '255 Озброєння та військова техніка',
  '256 Національна безпека (за окремими сферами забезпечення і видами діяльності)**',
  '261 Пожежна безпека',
  '262 Правоохоронна діяльність',
  '263 Цивільна безпека',
  '271 Річковий та морський транспорт',
  '272 Авіаційний транспорт',
  '273 Залізничний транспорт',
  '274 Автомобільний транспорт',
  '275 Транспортні технології (за видами)',
  '281 Публічне управління та адміністрування',
  '291 Міжнародні відносини, суспільні комунікації та регіональні студії',
  '292 Міжнародні економічні відносини',
  '293 Міжнародне право',
];

export const branches = [
  '01 Освіта/Педагогіка',
  '02 Культура і мистецтво',
  '03 Гуманітарні науки',
  '04 Богослов’я',
  '05 Соціальні та поведінкові науки',
  '06 Журналістика',
  '07 Управління та адміністрування',
  '08 Право',
  '09 Біологія',
  '10 Природничі науки',
  '11 Математика та статистика',
  '12 Інформаційні технології',
  '13 Механічна інженерія',
  '14 Електрична інженерія',
  '15 Автоматизація та приладобудування',
  '16 Хімічна та біоінженерія',
  '17 Електроніка та телекомунікації',
  '18 Виробництво та технології',
  '19 Архітектура та будівництво',
  '20 Аграрні науки та продовольство',
  '21 Ветеринарна медицина',
  '22 Охорона здоров’я',
  '23 Соціальна робота',
  '24 Сфера обслуговування',
  '25 Воєнні науки, національна безпека, безпека державного кордону',
  '26 Цивільна безпека',
  '27 Транспорт',
  '28 Публічне управління та адміністрування',
  '29 Міжнародні відносини',
];
