import { createAction, props } from '@ngrx/store';
import { EndUserKeyMedia, EndUserOwnerInfo } from '../../iit';

export enum SignActionTypes {
  UPDATE_KEY_MEDIAS = '[EUSign] Update Key medias',
  RETRIEVED_KEY_MEDIAS = '[EUSign] Retrieved Key medias',
  RETRIEVED_KEY_MEDIAS_ERROR = '[EUSign] Error when retrieved Key medias',
  READ_PRIVATE_KEY_BINARY = '[EUSign] Read private key (file)',
  READED_PRIVATE_KEY = '[EUSign] Private key readed',
  CLEARED_PRIVATE_KEY = '[EUSign] Private key cleared',
}

export const updateKeyMedias = createAction(SignActionTypes.UPDATE_KEY_MEDIAS);

export const retrievedKeyMedias = createAction(
  SignActionTypes.RETRIEVED_KEY_MEDIAS,
  props<{ keyMedias: EndUserKeyMedia[] }>(),
);

export const retrievedKeyMediasError = createAction(
  SignActionTypes.RETRIEVED_KEY_MEDIAS_ERROR,
  props<{ error: any }>(),
);

export const readedPrivateKey = createAction(
  SignActionTypes.READED_PRIVATE_KEY,
  props<{ currentPrivateKey: EndUserOwnerInfo }>(),
);

export const clearedPrivateKey = createAction(
  SignActionTypes.CLEARED_PRIVATE_KEY,
);
