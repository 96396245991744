import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignService } from '../../services/sign/sign.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { CASettings, EndUserConstants, EndUserKeyMedia } from '../../iit';
import { identifyWithKeyMedia } from '../../state/auth/auth.actions';
import {
  keyMediasNotFoundSelector,
  keyMediasSelector,
} from '../../state/sign/sign.selectors';
import { Observable, Subject } from 'rxjs';
import { updateKeyMedias } from '../../state/sign/sign.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-identification-keymedia-modal',
  templateUrl: './identification-keymedia-modal.component.html',
  styleUrls: ['./identification-keymedia-modal.component.css'],
})
export class IdentificationKeymediaModalComponent implements OnInit, OnDestroy {
  CAs: CASettings[];
  keyMediaForm: FormGroup;
  keyMedias$: Observable<EndUserKeyMedia[]> =
    this.store.select(keyMediasSelector);
  keyMediasNotFound$: Observable<boolean> = this.store.select(
    keyMediasNotFoundSelector,
  );
  _destroying$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private sign: SignService,
    private dialogRef: MatDialogRef<IdentificationKeymediaModalComponent>,
    private store: Store<AppState>,
  ) {}

  async ngOnInit() {
    this.initForm();
    this.sign.setLibraryType(EndUserConstants.EndUserLibraryType.SW);
    await this.sign.prepareLibrary();
    this.store.dispatch(updateKeyMedias());
    this.keyMediasNotFound$
      .pipe(takeUntil(this._destroying$))
      .subscribe((value) =>
        !value
          ? this.keyMediaForm.controls.keyMedia.enable()
          : this.keyMediaForm.controls.keyMedia.disable(),
      );
    this.CAs = await this.sign.getCAs();
  }

  ngOnDestroy() {
    this._destroying$.next(true);
    this._destroying$.complete();
  }

  initForm() {
    this.keyMediaForm = this.fb.group({
      CA: ['', [Validators.required]],
      keyMedia: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  identify() {
    this.store.dispatch(
      identifyWithKeyMedia({
        keyMedia: {
          ...this.keyMediaForm.controls.keyMedia.value,
          password: this.keyMediaForm.controls.password.value,
        } as EndUserKeyMedia,
        caName: this.keyMediaForm.controls.CA.value.issuerCNs[0],
      }),
    );

    this.dialogRef.close();
  }

  updateKeyMedias() {
    this.keyMediaForm.controls.keyMedia.reset();
    this.store.dispatch(updateKeyMedias());
  }
}
