<h3 mat-dialog-title>Ідентифікація користувача (апаратний носій)</h3>

<form [formGroup]="keyMediaForm">
  <mat-form-field>
    <mat-label>ЦСК</mat-label>
    <mat-select formControlName="CA">
      <mat-option *ngFor="let center of CAs" [value]="center">{{ center.issuerCNs[0] }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Носій ключової інформації</mat-label>
    <mat-select formControlName="keyMedia">
      <mat-option *ngFor="let keyMedia of keyMedias$ | ngrxPush" [value]="keyMedia">{{ keyMedia.visibleName }}</mat-option>
    </mat-select>
    <mat-hint *ngIf="keyMediasNotFound$ | ngrxPush">Жодного апаратного носія не було знайдено. Підключіть свій носій і натисніть кнопку "Оновити перелік НКІ"</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Пароль</mat-label>
    <input matInput type="password" autocomplete="off" formControlName="password">
  </mat-form-field>
</form>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Закрити</button>
  <button mat-raised-button
          (click)="updateKeyMedias()">
    Оновити перелік НКІ
  </button>
  <button mat-raised-button
          color="primary"
          [disabled]="!keyMediaForm.valid"
          (click)="identify()">
    Зчитати
  </button>
</mat-dialog-actions>

