<h2>Історія оновлень</h2>

<div [hidden]="!(updatesNotZero$ | ngrxPush)">
  <table mat-table
         [dataSource]="updates$">

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Тип</th>
      <td mat-cell
          *matCellDef="let update">
        {{ update.type === 'op' ? 'Освітні програми' : 'Науково-педагогічні працівники' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dataUpdatedOn">
      <th mat-header-cell *matHeaderCellDef>Дата актуальності даних</th>
      <td mat-cell
          *matCellDef="let update">
        {{ update.dataUpdatedOn | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="uploadedOn">
      <th mat-header-cell *matHeaderCellDef>Дата завантаження</th>
      <td mat-cell
          *matCellDef="let update">
        {{ update.uploadedOn | date:true }}
      </td>
    </ng-container>

    <ng-container matColumnDef="processedOn">
      <th mat-header-cell *matHeaderCellDef>Дата опрацювання</th>
      <td mat-cell
          *matCellDef="let update">
        {{ update.processed ? (update.processedOn | date:true) : '–' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="checksum">
      <th mat-header-cell *matHeaderCellDef>Контрольна сума (SHA256)</th>
      <td mat-cell
          *matCellDef="let update">
        {{ update.checksum }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>

  </table>

  <mat-paginator [length]="updatesTotalCount$ | ngrxPush"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 showFirstLastButtons="true"></mat-paginator>
</div>

<h4 [hidden]="updatesNotZero$ | ngrxPush">Схоже, нічого не знайдено...</h4>
