import { Injectable } from '@angular/core';
import {
  identifyWithBinaryKey,
  identifyWithKeyMedia,
  retrievedToken,
  retrievedTokenError,
} from '../auth/auth.actions';
import {
  retrievedAllHeisError,
  retrievedAllHeisSuccess,
  updateAllHeis,
} from '../dict/dict.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { loadingOff, loadingOn } from './loading.actions';

const loadingOnTriggers = [
  identifyWithKeyMedia,
  identifyWithBinaryKey,
  updateAllHeis,
];

const loadingOffTriggers = [
  retrievedToken,
  retrievedTokenError,
  retrievedAllHeisSuccess,
  retrievedAllHeisError,
];

@Injectable()
export class LoadingEffects {
  loadingOnListener$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...loadingOnTriggers),
      map(() => loadingOn()),
    );
  });

  loadingOffListener$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...loadingOffTriggers),
      map(() => loadingOff()),
    );
  });

  constructor(private actions$: Actions) {}
}
