<table mat-table
       *ngIf="(!!publications && publications.length > 0); else noPublicationsToDisplay"
       [dataSource]="publications">

  <ng-container matColumnDef="no">
    <th mat-header-cell *matHeaderCellDef>№</th>
    <td mat-cell
        *matCellDef="let publication; let i = index">
      {{ i + 1 }}
    </td>
  </ng-container>

  <ng-container matColumnDef="icons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell
        class="actions-column"
        *matCellDef="let publication">
      <mat-icon *ngIf="publication.hasSoleAuthorship"
                color="primary"
                matTooltip="Одноосібна">person</mat-icon>
      <mat-icon *ngIf="!publication.hasSoleAuthorship"
                color="primary"
                matTooltip="У співавторстві">people</mat-icon>
      <mat-icon *ngIf="publication.hasSecrets"
                color="primary"
                matTooltip="Державна таємниця / службова інформація">lock</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Тип</th>
    <td mat-cell
        *matCellDef="let publication">
      {{ enums.queryMap('publication', publication.type) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Опис</th>
    <td mat-cell
        *matCellDef="let publication">
      {{ publication.description }}
    </td>
  </ng-container>

  <ng-container matColumnDef="year">
    <th mat-header-cell *matHeaderCellDef>Рік</th>
    <td mat-cell *matCellDef="let publication">
      {{ publication.year }}
    </td>
  </ng-container>

  <ng-container matColumnDef="keywords">
    <th mat-header-cell *matHeaderCellDef>Ключові слова</th>
    <td mat-cell *matCellDef="let publication">
      {{ publication.keywords.join(', ') }}
    </td>
  </ng-container>

  <ng-container matColumnDef="doi">
    <th mat-header-cell *matHeaderCellDef>DOI</th>
    <td mat-cell *matCellDef="let publication">
      {{ publication.doi || '–' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let publication" class="actions-column">
      <a mat-icon-button
         *ngIf="publication.link"
         [href]="publication.link"
         target="_blank"
         matTooltip="Перейти до публікації">
        <mat-icon color="primary">link</mat-icon>
      </a>
      <button mat-icon-button
              type="button"
              *ngIf="changeable"
              (click)="editPublication(publication)"
              matTooltip="Редагувати публікацію">
        <mat-icon color="primary">edit</mat-icon>
      </button>
      <button mat-icon-button
              type="button"
              *ngIf="changeable"
              (click)="removePublication(publication)"
              matTooltip="Видалити публікацію">
        <mat-icon color="warn">cancel_rounded</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>

<ng-template #noPublicationsToDisplay>
  <p>Жодної публікації не додано...</p>
</ng-template>
