import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  retrievedKeyMedias,
  retrievedKeyMediasError,
  updateKeyMedias,
} from './sign.actions';
import { SignService } from '../../services/sign/sign.service';
import { map, switchMap, tap } from 'rxjs';

@Injectable()
export class SignEffects {
  updateKeyMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateKeyMedias),
      switchMap(() =>
        this.sign
          .getKeyMedias()
          .then((keyMedias) => retrievedKeyMedias({ keyMedias }))
          .catch((error) => retrievedKeyMediasError(error)),
      ),
    ),
  );

  constructor(private actions$: Actions, private sign: SignService) {}
}
