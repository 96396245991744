import { Component, ElementRef, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IdentificationFileModalComponent } from '../../modals/identification-file-modal/identification-file-modal.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import {
  currentUserIsHeiSelector,
  currentUserIsNaqaSelector,
  userAuthorizedSelector,
} from '../../state/auth/auth.selectors';
import { clearToken, identifyWithBinaryKey, identifyWithDebugParams } from '../../state/auth/auth.actions';
import { Router } from '@angular/router';
import { IdentificationKeymediaModalComponent } from '../../modals/identification-keymedia-modal/identification-keymedia-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AboutComponent } from '../../elements/about/about.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  userAuthorized$ = this.store.select(userAuthorizedSelector);
  currentUserIsHei$ = this.store.select(currentUserIsHeiSelector);
  currentUserIsNaqa$ = this.store.select(currentUserIsNaqaSelector);

  @ViewChild('aboutOpener', { read: ElementRef }) aboutOpener: ElementRef;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private router: Router,
    private bottomSheet: MatBottomSheet,
  ) {}

  openIdentificationFileModal() {
    this.dialog.open(IdentificationFileModalComponent);
  }

  openIdentificationKeymediaModal() {
    this.dialog.open(IdentificationKeymediaModalComponent);
  }

  debugLogin(type: number): void {
    let users = [
      { //Заклад
        isPrivatePerson: false,
        isNaqa: false,
        isMon: false,
        hei: {
          heiId: 6507,
          name: 'Український державний університет науки і технологій',
          edrpou: '44165850',
        },
        name: 'Debug: Користувач Закладу',
        rnokpp: 'rnokpp',
        edrpou: '44165850',
      },
      { //Naqa
        isPrivatePerson: false,
        isNaqa: true,
        isMon: false,
        name: 'Debug: Користувач NAQA',
        rnokpp: 'rnokpp',
      },
      { //MON
        isPrivatePerson: false,
        isNaqa: false,
        isMon: true,
        name: 'Debug: Користувач MON',
        rnokpp: 'rnokpp',
      },
    ];
    this.store.dispatch(
      identifyWithDebugParams({
        user: users[type-1]
      }),
    );
    /*
    export interface UserInfo {
      isPrivatePerson: boolean;
      isNaqa: boolean;
      isMon: boolean;
      hei?: Hei;
      name: string;
      rnokpp: string;
      edrpou?: string;
  }
*/
  }

  async logout() {
    await this.router.navigate(['']);
    this.store.dispatch(clearToken());
  }

  openAbout() {
    const ref = this.bottomSheet.open(AboutComponent);
    ref
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => this.aboutOpener.nativeElement.blur());
  }

  public readonly environment = environment;
}
