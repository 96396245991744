import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'date' })
export class CustomDatePipe implements PipeTransform {
  transform(value: string, includeTime: boolean = false): string {
    return moment(value).format(
      includeTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY',
    );
  }
}
