import { Injectable } from '@angular/core';
import { DatatableStoreTemplate } from '../../misc/store-templates/datatable.store-template';
import { ApiService } from '../../services/api/api.service';
import { IMessageInfo } from '../../interfaces/messages/message-info.interface';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';
import { AllMessagesFilterFields } from '../../types/datatable.types';

@Injectable()
export class AllMessagesStore extends DatatableStoreTemplate<
  IMessageInfo,
  AllMessagesFilterFields
> {
  constructor(override api: ApiService, override store: Store<AppState>) {
    super('/message', api, store);
  }
}
