import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ISvrMemberApiResponse,
  ISvrMemberState,
} from '../../interfaces/common/svr-member.interface';
import { EnumsService } from '../../services/enums/enums.service';
import { IPublicationApiDto } from '../../interfaces/common/publication.interface';
import { MatDialog } from '@angular/material/dialog';
import { PublicationDisplayModalComponent } from '../../modals/publication-display-modal/publication-display-modal.component';

type TSvrMemberInput<T extends boolean> = T extends true
  ? ISvrMemberApiResponse
  : T extends false
  ? ISvrMemberState
  : never;

@Component({
  selector: 'app-svr-members-show',
  templateUrl: './svr-members-show.component.html',
  styleUrls: ['./svr-members-show.component.css'],
})
export class SvrMembersShowComponent<T extends boolean> {
  @Input() receivedFromApi: T;
  @Input() changeable: boolean;
  @Input() svrMembers: TSvrMemberInput<T>[] | null | undefined;
  @Output() svrMemberEdit = new EventEmitter<TSvrMemberInput<T>>();
  @Output() svrMemberRemoval = new EventEmitter<TSvrMemberInput<T>>();
  readonly columns = [
    'no',
    'svrMemberType',
    'name',
    'icons',
    'heiName',
    'degree',
    'actions',
  ];
  public TSvrMemberInput: TSvrMemberInput<T>;

  constructor(public enums: EnumsService, private dialog: MatDialog) {}

  editSvrMember(svrMember: TSvrMemberInput<T>) {
    this.svrMemberEdit.emit(svrMember);
  }

  removeSvrMember(svrMember: TSvrMemberInput<T>) {
    this.svrMemberRemoval.emit(svrMember);
  }

  openPublicationsDialog(publications: IPublicationApiDto[]) {
    this.dialog.open(PublicationDisplayModalComponent, {
      data: { publications, receivedFromApi: this.receivedFromApi },
    });
  }

  sortSvrMembers(members: TSvrMemberInput<T>[]): TSvrMemberInput<T>[] {
    return members.sort((first, second) => {
      if ('active' in first && 'active' in second) {
        if (first.active == second.active) {
          return first.type - second.type;
        } else if (first.active && !second.active) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return first.type - second.type;
      }
    });
  }
}
