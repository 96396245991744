import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  IHeiApiResponse,
  IHeiDetailedApiResponse,
} from '../../interfaces/dict/hei.interface';
import {
  EdeboOpInfoDto,
  IOpApiResponse,
} from '../../interfaces/dict/op.interface';
import {
  IDefenseDetailedResponse,
  IDefenseMultipleApiResponse,
} from '../../interfaces/common/defense.interface';
import {
  IMessageInfo,
  IMessagesResponse,
} from '../../interfaces/messages/message-info.interface';
import { TMultipleEntitiesApiResponse } from '../../types/api.types';
import { IUpdateApiResponse } from '../../interfaces/dict/update.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly apiUri = environment.apiUri;

  constructor(private http: HttpClient) {}

  /**
   * Отримання даних про усі заклади освіти/наукові установи.
   */
  getAllHeis(): Observable<IHeiApiResponse[]> {
    return this.http.get<IHeiApiResponse[]>(this.apiUri + '/hei');
  }

  /**
   * Отримання даних про один заклад освіти/наукову установу за ID у ЄДЕБО.
   * @param heiId ID закладу/установи в ЄДЕБО
   */
  getHeiByHeiId(heiId: number): Observable<IHeiDetailedApiResponse> {
    const params = new HttpParams().set('ops', true).set('teachers', true);
    return this.http.get<IHeiDetailedApiResponse>(
      this.apiUri + `/hei/${heiId}`,
      {
        params,
      },
    );
  }

  /**
   * Отримання даних про один заклад освіти/наукову установу за кодом ЄДРПОУ.
   * @param edrpou Код ЄДРПОУ
   */
  getHeiByEdrpou(edrpou: string): Observable<IHeiDetailedApiResponse> {
    const params = new HttpParams().set('ops', true).set('teachers', true);
    return this.http.get<IHeiDetailedApiResponse>(
      this.apiUri + `/hei/edrpou/${edrpou}`,
      {
        params,
      },
    );
  }

  /**
   * Отримання даних про освітню програму за її ID у ЄДЕБО.
   * @param opId ID ОП у ЄДЕБО
   */
  getOpByOpId(opId: number): Observable<IOpApiResponse> {
    return this.http.get<IOpApiResponse>(this.apiUri + `/op/${opId}`);
  }

  /**
   * Отримання даних про усі захисти.
   */
  getAllDefenses(): Observable<IDefenseMultipleApiResponse> {
    return this.http.get<IDefenseMultipleApiResponse>(this.apiUri + '/defense');
  }

  /**
   * Отримання даних про один захист за його ID у системі.
   * @param defenseId ID захисту
   */
  getDefenseByDefenseId(
    defenseId: number,
  ): Observable<IDefenseDetailedResponse> {
    return this.http.get<IDefenseDetailedResponse>(
      this.apiUri + `/defense/${defenseId}`,
    );
  }

  /**
   * Отримання даних про ОП, за якою відбувається захист, за даними ЄДЕБО (via NAQA.Registry).
   * @param defenseId ID захисту
   */
  getEdeboOpInfoByDefenseId(defenseId: number): Observable<EdeboOpInfoDto> {
    return this.http.get<EdeboOpInfoDto>(
      this.apiUri + `/defense/edebo-op-info/${defenseId}`,
    );
  }

  /**
   * Перевірка, чи певний захист відбудеться у певному закладі освіти/науковій установі.
   * @param defenseId ID захисту
   * @param heiId ID закладу/установи у ЄДЕБО
   */
  checkDefenseByHeiId(
    defenseId: number,
    heiId: number,
  ): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>(
      this.apiUri + '/defense/check-hei',
      { defenseId, heiId },
    );
  }

  /**
   * Отримання переліку усіх повідомлень.
   */
  getAllMessages(): Observable<IMessagesResponse> {
    return this.http.get<IMessagesResponse>(this.apiUri + '/message');
  }

  /**
   * Отримання даних про ID захисту за ID повідомлення
   * @param messageId ID повідомлення
   */
  getDefenseIdByMessageId(
    messageId: number,
  ): Observable<{ defenseId: number }> {
    return this.http.get<{ defenseId: number }>(
      this.apiUri + `/message/get-defense-id/${messageId}`,
    );
  }

  /**
   * Направлення нового повідомлення.
   * @param message JSON-повідомлення у форматі текстового рядка (попередньо слід JSON.stringify)
   * @param messageFile PDF-файл повідомлення
   * @param messageSignature Електронний підпис до файла повідомлення
   * @param annexFile PDF-файл додатку до повідомлення
   * @param annexSignature Електронний підпис до файла додатку
   */
  newMessage(
    message: string,
    messageFile: Blob,
    messageSignature: Blob,
    annexFile?: Blob,
    annexSignature?: Blob,
  ): Observable<IMessageInfo> {
    const formData: FormData = new FormData();
    formData.set('message', message);
    formData.set('messageFile', messageFile);
    formData.set('messageSignature', messageSignature);
    if (annexFile && annexSignature) {
      formData.set('annexFile', annexFile);
      formData.set('annexSignature', annexSignature);
    }
    return this.http.post<IMessageInfo>(
      this.apiUri + '/message/new-message',
      formData,
    );
  }

  getMultipleEntitiesWithPagination<T>(
    skip: number,
    limit: number,
    apiPath: string,
    filterString?: string,
  ): Observable<TMultipleEntitiesApiResponse<T>> {
    let params = new HttpParams().set('skip', skip).set('limit', limit);
    if (filterString !== undefined) params = params.set('filter', filterString);
    return this.http.get<TMultipleEntitiesApiResponse<T>>(
      this.apiUri + apiPath,
      {
        params,
      },
    );
  }

  /**
   * Направлення нового повідомлення.
   * @param type Тип оновлення ('op' | 'teacher')
   * @param dataUpdatedOn Дата актуальності даних
   * @param file Файл з оновленням довідника
   */
  uploadUpdate(
    type: string,
    dataUpdatedOn: string,
    file: Blob,
  ): Observable<IUpdateApiResponse> {
    const formData: FormData = new FormData();
    formData.set('type', type);
    formData.set('dataUpdatedOn', dataUpdatedOn);
    formData.set('file', file);
    return this.http.post<IUpdateApiResponse>(
      this.apiUri + '/update/upload',
      formData,
    );
  }
}
