import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, of, switchMap } from 'rxjs';
import { ApiService } from '../../services/api/api.service';
import {
  clearApplicantHei,
  retrievedAllHeisError,
  retrievedAllHeisSuccess,
  retrievedApplicantHeiError,
  retrievedApplicantHeiSuccess,
  updateAllHeis,
} from './dict.actions';
import { clearToken, retrievedToken } from '../auth/auth.actions';
import jwt_decode from 'jwt-decode';
import { IJwt } from '../../interfaces/identification/identification.interfaces';

@Injectable()
export class DictEffects {
  updateAllHeis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAllHeis),
      switchMap(() =>
        this.api.getAllHeis().pipe(
          map((allHeis) => retrievedAllHeisSuccess({ allHeis })),
          catchError(() => of(retrievedAllHeisError())),
        ),
      ),
    ),
  );

  updateApplicantHei$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrievedToken),
      map((action) => jwt_decode<IJwt>(action.accessToken).sub.hei),
      filter((hei) => hei !== undefined),
      switchMap((hei) =>
        this.api.getHeiByHeiId(hei!.heiId).pipe(
          map((applicantHei) => retrievedApplicantHeiSuccess({ applicantHei })),
          catchError(() => of(retrievedApplicantHeiError())),
        ),
      ),
    ),
  );

  clearApplicantHei$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearToken),
      map(() => clearApplicantHei()),
    ),
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
