import { createReducer, on } from '@ngrx/store';
import { initialDictState } from './dict.state';
import {
  clearApplicantHei,
  retrievedAllHeisError,
  retrievedAllHeisSuccess,
  retrievedApplicantHeiError,
  retrievedApplicantHeiSuccess,
} from './dict.actions';

export const dictReducer = createReducer(
  initialDictState,
  on(retrievedAllHeisSuccess, (state, { allHeis }) => {
    allHeis = [...allHeis].sort((one, two) => one.name.localeCompare(two.name));
    return {
      ...state,
      allHeis,
    };
  }),
  on(retrievedAllHeisError, (state) => ({ ...state, allHeis: [] })),
  on(retrievedApplicantHeiSuccess, (state, { applicantHei }) => ({
    ...state,
    applicantHei,
  })),
  on(retrievedApplicantHeiError, (state) => ({ ...state, applicantHei: null })),
  on(clearApplicantHei, (state) => ({ ...state, applicantHei: null })),
);
