<div [ngSwitch]="type">
  <h3>Підтвердження</h3>

  <div *ngSwitchCase="'hei'">
    <p>Я підтверджую, що:</p>

    <ul>
      <li>я належним чином уповноважений/а закладом освіти/науковою устаною на подання цього повідомлення, і за потреби надам документ, який підтверджує ці повноваження</li>
      <li>усі відомості, викладені у цьому повідомленні, є достовірними</li>
    </ul>
  </div>

  <div *ngSwitchCase="'hei-short'">
    <p>Я підтверджую, що я належним чином уповноважений/а закладом освіти/науковою устаною на подання цього повідомлення, і за потреби надам документ, який підтверджує ці повноваження.</p>
  </div>

  <div *ngSwitchCase="'mon'">
    <p>Я підтверджую, що я належним чином уповноважений/а Міністерством освіти і науки України на подання цього повідомлення.</p>
  </div>

  <div *ngSwitchCase="'naqa'">
    <p>Я підтверджую, що я належним чином уповноважений/а Національним агентством із забезпечення якості вищої освіти на подання цього повідомлення.</p>
  </div>
</div>
