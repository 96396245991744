import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, take } from 'rxjs';
import { IDefenseDetailedResponse } from '../interfaces/common/defense.interface';
import { ApiService } from '../services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class DefenseResolver implements Resolve<IDefenseDetailedResponse> {
  constructor(private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<IDefenseDetailedResponse> {
    return this.api.getDefenseByDefenseId(route.params.defenseId).pipe(take(1));
  }
}
