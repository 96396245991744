import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { SignService } from '../sign/sign.service';
import { base64ToUInt8Array } from '../../misc/utils';
import {
  INonce,
  IToken,
} from '../../interfaces/identification/identification.interfaces';
import { EndUserConstants, EndUserKeyMedia } from '../../iit';

@Injectable({
  providedIn: 'root',
})
export class IdentificationService {
  readonly apiUri = environment.apiUri;

  constructor(private http: HttpClient, private sign: SignService) {}

  /**
   * Ідентифікація з використанням файлового ключа.
   * @param keyFile Файл ключа електронного підпису.
   * @param password Пароль до особистого ключа.
   * @param isJks Чи особистий ключ представлений у форматі JKS-файла.
   * @param caName Найменування ЦСК.
   */
  identifyWithBinaryKey(
    keyFile: Uint8Array | Blob,
    password: string,
    isJks: boolean,
    caName: string,
  ): Promise<string> {
    return this.obtainNonce()
      .then(async (nonce) => base64ToUInt8Array(nonce))
      .then(async (nonce) => {
        this.sign.setLibraryType(EndUserConstants.EndUserLibraryType.JS);
        await this.sign.prepareLibrary();
        await this.sign.readPrivateKeyBinary(keyFile, password, isJks, caName);
        return this.sign.signData(nonce, 'base64');
      })
      .then((signature) => this.obtainToken(signature));
  }

  /**
   * Ідентифікація з використанням апаратного носія.
   * @param keyMedia Відомості апаратного носія у форматі, визначеному в документації бібліотеки.
   * @param caName Найменування ЦСК.
   */
  identifyWithKeyMedia(
    keyMedia: EndUserKeyMedia,
    caName: string,
  ): Promise<string> {
    return this.obtainNonce()
      .then((nonce) => base64ToUInt8Array(nonce))
      .then(async (nonce) => {
        this.sign.setLibraryType(EndUserConstants.EndUserLibraryType.SW);
        await this.sign.prepareLibrary();
        await this.sign.readPrivateKeyMedia(keyMedia, caName);
        return this.sign.signData(nonce, 'base64');
      })
      .then((signature) => this.obtainToken(signature));
  }

  identifyWithDebugParams(
    data: any
  ): Promise<string> {
    return this.obtainNonce()
      .then(async (nonce) => base64ToUInt8Array(nonce))
      .then(async (nonce) => {
        return "debugsignature";
      })
      .then((signature) => this.obtainTokenDebug(signature, data));
  }

  /**
   * Отримання одноразового рядка даних (nonce) для проведення авторизації через електронний підпис.
   */
  obtainNonce(): Promise<string> {
    return firstValueFrom(
      this.http
        .get<INonce>(this.apiUri + '/auth/nonce', {
          withCredentials: true,
        })
        .pipe(map((value) => value.nonce)),
    );
  }

  /**
   * Отримання JWT-токена на основі підпису до попередньо отриманого nonce.
   * @param signature Електронний підпис nonce у форматі base64.
   */
  obtainToken(signature: string): Promise<string> {
    return firstValueFrom(
      this.http
        .post<IToken>(
          this.apiUri + '/auth/login',
          { signature },
          { withCredentials: true },
        )
        .pipe(map((value) => value.accessToken)),
    );
  }

  obtainTokenDebug(signature: string, userData: any): Promise<string> {
    return firstValueFrom(
      this.http
        .post<IToken>(
          this.apiUri + '/auth/loginDebug',
          {signature, user: userData },
          { withCredentials: true },
        )
        .pipe(map((value) => value.accessToken)),
    );
  }
}
