<h3 mat-dialog-title>Ідентифікація користувача (файловий носій)</h3>

<form [formGroup]="keyFileForm">
  <mat-form-field>
    <mat-label>ЦСК</mat-label>
    <mat-select formControlName="CA">
      <mat-option *ngFor="let center of CAs" [value]="center">{{ center.issuerCNs[0] }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Файл ключа</mat-label>
    <ngx-mat-file-input formControlName="privateKeyFile" accept=".dat,.jks,.zs2,.pk8,.pfx"></ngx-mat-file-input>
    <mat-hint>Файл ключа може мати розширення .dat, .jks, .zs2, .pk8 або .pfx</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Пароль</mat-label>
    <input matInput type="password" autocomplete="off" formControlName="password">
  </mat-form-field>
</form>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Закрити</button>
  <button mat-raised-button
          color="primary"
          [disabled]="!keyFileForm.valid"
          (click)="identify()">
    Зчитати
  </button>
</mat-dialog-actions>
