import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './state';
import { version } from './misc/const';
import { isLoadingSelector } from './state/loading/loading.selectors';
import { takeUntil } from 'rxjs/operators';
import { loadingOff, loadingOn } from './state/loading/loading.actions';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  readonly isDev = window.location.host
    .split('.')
    .some((value) => /dev|localhost/.test(value));
  isLoading$ = this.store.select(isLoadingSelector);
  _destroying$ = new Subject<boolean>();
  @ViewChild('content') content: ElementRef;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    console.log(`Running NAQA.Svr v. ${version}`);
    this.router.events.pipe(takeUntil(this._destroying$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.store.dispatch(loadingOn());
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.store.dispatch(loadingOff());
      }

      if (event instanceof NavigationEnd) {
        this.content.nativeElement.scrollTop = 0;
      }
    });
  }

  ngAfterContentInit() {
    this.isLoading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((value) =>
        value ? this.spinner.show() : this.spinner.hide(),
      );
  }

  ngOnDestroy() {
    this._destroying$.next(true);
    this._destroying$.complete();
  }

  public readonly environment = environment;
}
