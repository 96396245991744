import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { EnumsService } from '../../services/enums/enums.service';

@Component({
  selector: 'app-all-messages-filter',
  templateUrl: './all-messages-filter.component.html',
  styleUrls: ['./all-messages-filter.component.css'],
})
export class AllMessagesFilterComponent implements OnInit {
  allMessagesFilterForm: FormGroup;
  filterIsValid$: Observable<boolean>;

  constructor(private fb: FormBuilder, public enums: EnumsService) {}

  ngOnInit(): void {
    this.initForm();
    this.filterIsValid$ = this.allMessagesFilterForm.valueChanges.pipe(
      map(
        (value) =>
          (!!value.type && value.type.length > 0) ||
          (!!value.dateStart && !!value.dateEnd),
      ),
    );
  }

  initForm() {
    this.allMessagesFilterForm = this.fb.group({
      type: [],
      dateStart: null,
      dateEnd: null,
    });
  }
}
