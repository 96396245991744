import { createAction, props } from '@ngrx/store';
import { EndUserKeyMedia } from '../../iit';

export enum AuthActionTypes {
  IDENTIFY_WITH_BINARY_KEY = '[Auth] Identify with binary key',
  IDENTIFY_WITH_KEY_MEDIA = '[Auth] Identify with key media',
  RETRIEVED_TOKEN_SUCCESS = '[Auth] Obtained JWT token',
  RETRIEVED_TOKEN_ERROR = '[Auth] Error obtaining JWT token',
  CLEAR_TOKEN = '[Auth] Cleared JWT token',
  IDENTIFY_WITH_DEBUG_PARAMS = '[Auth] Identify with debug params',
}

export const identifyWithBinaryKey = createAction(
  AuthActionTypes.IDENTIFY_WITH_BINARY_KEY,
  props<{
    privateKeyFile: File;
    password: string;
    isJks: boolean;
    caName: string;
  }>(),
);

export const identifyWithDebugParams = createAction(
  AuthActionTypes.IDENTIFY_WITH_DEBUG_PARAMS,
  props<{
    user: any;
  }>(),
);


export const identifyWithKeyMedia = createAction(
  AuthActionTypes.IDENTIFY_WITH_KEY_MEDIA,
  props<{ keyMedia: EndUserKeyMedia; caName: string }>(),
);

export const retrievedToken = createAction(
  AuthActionTypes.RETRIEVED_TOKEN_SUCCESS,
  props<{ accessToken: string }>(),
);

export const retrievedTokenError = createAction(
  AuthActionTypes.RETRIEVED_TOKEN_ERROR,
  props<{ error: any }>(),
);

export const clearToken = createAction(AuthActionTypes.CLEAR_TOKEN);
