import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllUpdatesStore } from './all-updates.store';

@Component({
  selector: 'app-all-updates',
  templateUrl: './all-updates.component.html',
  styleUrls: ['./all-updates.component.css'],
  providers: [AllUpdatesStore],
})
export class AllUpdatesComponent implements OnInit, AfterViewInit, OnDestroy {
  updates$ = this.componentStore.entities();
  updatesNotZero$ = this.componentStore.entitiesNotZero();
  updatesTotalCount$ = this.componentStore.entitiesTotalCount();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly columns = [
    'type',
    'dataUpdatedOn',
    'uploadedOn',
    'processedOn',
    'checksum',
  ];
  _destroying = new Subject<boolean>();

  constructor(private componentStore: AllUpdatesStore) {}

  ngOnInit(): void {
    this.componentStore.setPaginator({ skip: 0, limit: 10 });
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(takeUntil(this._destroying)).subscribe((event) =>
      this.componentStore.setPaginator({
        skip: event.pageSize * event.pageIndex,
        limit: event.pageSize,
      }),
    );
  }

  ngOnDestroy() {
    this._destroying.next(true);
    this._destroying.complete();
  }
}
