import { createAction, props } from '@ngrx/store';
import {
  IHeiApiResponse,
  IHeiDetailedApiResponse,
} from '../../interfaces/dict/hei.interface';

export enum DictActionTypes {
  UPDATE_ALL_HEIS = '[All HEIs list] Update all HEIs list',
  RETRIEVED_ALL_HEIS_SUCCESS = '[All HEIs list/API] Retrieved all HEIs list from API',
  RETRIEVED_ALL_HEIS_ERROR = '[All HEIs list/API] Error while retrieving all HEIs list from API',
  RETRIEVED_APPLICANT_HEI_SUCCESS = '[Applicant HEI/API] Retrieved applicant HEI info from API',
  RETRIEVED_APPLICANT_HEI_ERROR = '[Applicant HEI/API] Error while retrieving applicant HEI info from API',
  CLEAR_APPLICANT_HEI = '[Applicant HEI] Clear applicant HEI info',
}

export const updateAllHeis = createAction(DictActionTypes.UPDATE_ALL_HEIS);

export const retrievedAllHeisSuccess = createAction(
  DictActionTypes.RETRIEVED_ALL_HEIS_SUCCESS,
  props<{ allHeis: IHeiApiResponse[] }>(),
);

export const retrievedAllHeisError = createAction(
  DictActionTypes.RETRIEVED_ALL_HEIS_ERROR,
);

export const retrievedApplicantHeiSuccess = createAction(
  DictActionTypes.RETRIEVED_APPLICANT_HEI_SUCCESS,
  props<{ applicantHei: IHeiDetailedApiResponse }>(),
);

export const retrievedApplicantHeiError = createAction(
  DictActionTypes.RETRIEVED_APPLICANT_HEI_ERROR,
);

export const clearApplicantHei = createAction(
  DictActionTypes.CLEAR_APPLICANT_HEI,
);
