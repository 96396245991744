import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumsService } from '../../services/enums/enums.service';
import { map, Observable } from 'rxjs';
import { branches, newSpec } from '../../misc/const';

@Component({
  selector: 'app-all-defenses-filter',
  templateUrl: './all-defenses-filter.component.html',
  styleUrls: ['./all-defenses-filter.component.css'],
})
export class AllDefensesFilterComponent implements OnInit {
  allDefensesFilterForm: FormGroup;
  filterIsValid$: Observable<boolean>;
  readonly branches = branches;
  readonly specs = newSpec;

  constructor(private fb: FormBuilder, public enums: EnumsService) {}

  ngOnInit(): void {
    this.initForm();
    this.filterIsValid$ = this.allDefensesFilterForm.valueChanges.pipe(
      map(
        (value) =>
          !!value.text ||
          !!value.hei ||
          (!!value.state && value.state.length > 0) ||
          (!!value.branches && value.branches.length > 0) ||
          (!!value.specs && value.specs.length > 0) ||
          (!!value.defenseDateStart && !!value.defenseDateEnd) ||
          (!!value.svrLastDecisionDateStart &&
            !!value.svrLastDecisionDateEnd) ||
          value.complaintsPresent,
      ),
    );
  }

  initForm() {
    this.allDefensesFilterForm = this.fb.group({
      text: null,
      hei: null,
      state: [],
      complaintsPresent: false,
      branches: [],
      specs: [],
      defenseDateStart: null,
      defenseDateEnd: null,
      svrLastDecisionDateStart: null,
      svrLastDecisionDateEnd: null,
    });
  }
}
