<table mat-table
       *ngIf="messages && messages.length > 0; else noMessages"
       [class.small-icons]="smallIcons"
       [dataSource]="messages">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell
        *matCellDef="let message">
      <strong>{{ message.messageId }}</strong>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Тип повідомлення</th>
    <td mat-cell
        *matCellDef="let message">
      {{ enums.queryMap('message', message.messageType) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Дата надходження</th>
    <td mat-cell
        *matCellDef="let message">
      {{ message.receivedOn | date:true }}
    </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>Автор</th>
    <td mat-cell
        *matCellDef="let message">
      {{ message.user.name }}
      <span *ngIf="message.user.hei"> ({{ message.user.hei.name }})</span>
      <span *ngIf="message.user.isNaqa"> (Національне агентство із забезпечення якості вищої освіти)</span>
      <span *ngIf="message.user.isMon"> (Міністерство освіти і науки України)</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="link">
    <th mat-header-cell *matHeaderCellDef class="actions-column">Документ</th>
    <td mat-cell
        *matCellDef="let message"
        class="actions-column">
      <a mat-icon-button
         matTooltip="Завантажити повідомлення"
         [href]="apiUri + '/file/' + message.messageFileName">
        <mat-icon color="primary">download</mat-icon>
      </a>

      <a mat-icon-button
         matTooltip="Завантажити підпис"
         [href]="apiUri + '/file/' + message.messageSignatureName">
        <mat-icon color="primary">approval</mat-icon>
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="annex">
    <th mat-header-cell *matHeaderCellDef class="actions-column">Додаток</th>
    <td mat-cell
        *matCellDef="let message"
        class="actions-column">
      <span *ngIf="message.annexFileName && message.annexSignatureName">
        <a mat-icon-button
           matTooltip="Завантажити додаток"
           [href]="apiUri + '/file/' + message.annexFileName">
        <mat-icon color="primary">attachment</mat-icon>
      </a>

      <a mat-icon-button
         matTooltip="Завантажити підпис"
         [href]="apiUri + '/file/' + message.annexSignatureName">
        <mat-icon color="primary">approval</mat-icon>
      </a>
      </span>

    </td>
  </ng-container>

  <ng-container *ngIf="showLinkToDefense" matColumnDef="linkToDefense">
    <th mat-header-cell *matHeaderCellDef class="actions-column">Захист</th>
    <td mat-cell
        *matCellDef="let message"
        class="actions-column">
      <a mat-icon-button
         matTooltip="Перейти на сторінку захисту"
         [routerLink]="'/defense/' + message.defense.defenseId?.toString()">
        <mat-icon color="primary">forward</mat-icon>
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>

</table>

<ng-template #noMessages>
  <p>Упс! Поки жодного повідомлення не подано.</p>
</ng-template>
