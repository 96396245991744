import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { retrievedTokenError } from '../auth/auth.actions';
import { retrievedKeyMediasError } from '../sign/sign.actions';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import { loadingZero } from '../loading/loading.actions';

const errorActions = [retrievedTokenError, retrievedKeyMediasError];

@Injectable()
export class ErrorEffects {
  errorListener$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...errorActions),
      tap(({ error }) => {
        const msg =
          error.rejection?.message ?? error.error?.message ?? error.message;
        this.snackbar.openSnackBar(msg, true);
        console.error(error);
      }),
      map(() => loadingZero()),
    ),
  );

  constructor(private actions$: Actions, private snackbar: SnackbarService) {}
}
