<table mat-table
       *ngIf="svrMembers && svrMembers.length > 0; else noSvrMembers"
       [dataSource]="sortSvrMembers(svrMembers)">

  <ng-container matColumnDef="no">
    <th mat-header-cell *matHeaderCellDef>№</th>
    <td mat-cell
        *matCellDef="let svrMember; let i = index"
        [class.inactive-record]="svrMember.active !== undefined && !svrMember.active">
      {{ i + 1 }}
    </td>
  </ng-container>

  <ng-container matColumnDef="svrMemberType">
    <th mat-header-cell *matHeaderCellDef>Роль</th>
    <td mat-cell
        *matCellDef="let svrMember"
        [class.inactive-record]="svrMember.active !== undefined && !svrMember.active">
      {{ enums.queryMap('svrMember', svrMember.type) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Ім'я</th>
    <td mat-cell *matCellDef="let svrMember"
        [class.inactive-record]="svrMember.active !== undefined && !svrMember.active">
      {{ svrMember.isForeigner ? svrMember.foreignName : svrMember.isMilitary ? svrMember.militaryName : svrMember.teacher.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="icons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell
        *matCellDef="let svrMember">
      <mat-icon *ngIf="svrMember.isForeigner"
                color="primary"
                matTooltip="Іноземний вчений">language</mat-icon>
      <mat-icon *ngIf="svrMember.isMilitary"
                color="primary"
                matTooltip="Працівник ВВНЗ">military_tech</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="heiName">
    <th mat-header-cell *matHeaderCellDef>Заклад, посада</th>
    <td mat-cell *matCellDef="let svrMember"
        [class.inactive-record]="svrMember.active !== undefined && !svrMember.active">
      <span *ngIf="receivedFromApi">
        {{ svrMember.isForeigner ? svrMember.foreignHeiName : svrMember.isMilitary ? svrMember.militaryHei.name : svrMember.teacher.hei.name }}
      </span>
      <span *ngIf="!receivedFromApi">
        {{ svrMember.isForeigner ? svrMember.foreignHeiName : svrMember.isMilitary ? svrMember.hei.name : svrMember.hei.name }}
      </span>
      &nbsp;({{ svrMember.isForeigner ? svrMember.foreignPosition : svrMember.isMilitary ? svrMember.militaryPosition : svrMember.teacher.position + ', ' + svrMember.teacher.positionStatus }})
    </td>
  </ng-container>

  <ng-container matColumnDef="degree">
    <th mat-header-cell *matHeaderCellDef>Науковий ступінь</th>
    <td mat-cell *matCellDef="let svrMember"
        [class.inactive-record]="svrMember.active !== undefined && !svrMember.active">
      {{ enums.queryMap('degree', svrMember.degreeType) }}, {{ svrMember.degreeSpec }}
      <div *ngIf="!!svrMember.phdAwardedOn">
        <br><br>
        <small>Cтупінь PhD (КН) присуджено {{ svrMember.phdAwardedOn | date }}</small>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let svrMember" class="actions-column">
      <button mat-icon-button
              type="button"
              (click)="openPublicationsDialog(svrMember.publications)"
              matTooltip="Переглянути публікації члена ради">
        <mat-icon color="primary">description</mat-icon>
      </button>
      <a mat-icon-button
         type="button"
         *ngIf="svrMember.orcid !== undefined"
         [href]="'https://orcid.org/' + svrMember.orcid"
         target="_blank"
         matTooltip="Профіль ORCID">
        <mat-icon color="primary">badge</mat-icon>
      </a>
      <button mat-icon-button
              type="button"
              *ngIf="changeable"
              (click)="editSvrMember(svrMember)"
              matTooltip="Редагувати відомості про члена ради">
        <mat-icon color="primary">edit</mat-icon>
      </button>
      <button mat-icon-button
              type="button"
              *ngIf="changeable"
              (click)="removeSvrMember(svrMember)"
              matTooltip="Видалити члена ради">
        <mat-icon color="warn">cancel_rounded</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>

</table>

<ng-template #noSvrMembers>
  <p>Жодного члена разової ради не додано...</p>
</ng-template>
