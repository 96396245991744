import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AllDefensesComponent } from './main/all-defenses/all-defenses.component';
import { AllMessagesComponent } from './main/all-messages/all-messages.component';
import { DefenseDisplayComponent } from './main/defense-display/defense-display.component';
import { DefenseResolver } from './resolvers/defense.resolver';
import { ForbiddenComponent } from './main/forbidden/forbidden.component';
import { UpdateComponent } from './main/update/update.component';
import { AllUpdatesComponent } from './main/all-updates/all-updates.component';
import { NaqaGuard } from './guards/naqa.guard';
import { DefenseEdeboOpInfoResolver } from './resolvers/defense-edebo-op-info.resolver';

const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  {
    path: '',
    redirectTo: '/all-defenses',
    pathMatch: 'full',
  },
  {
    path: 'all-defenses',
    component: AllDefensesComponent,
  },
  {
    path: 'all-messages',
    component: AllMessagesComponent,
  },
  {
    path: 'defense/:defenseId',
    component: DefenseDisplayComponent,
    resolve: {
      defenseResponse: DefenseResolver,
      edeboOpInfo: DefenseEdeboOpInfoResolver,
    },
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications.module').then((m) => m.NotificationsModule),
  },
  {
    path: 'update',
    canActivate: [NaqaGuard],
    component: UpdateComponent,
  },
  {
    path: 'all-updates',
    canActivate: [NaqaGuard],
    component: AllUpdatesComponent,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
