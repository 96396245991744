import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TPublicationInput } from '../../types/publication.types';

@Component({
  selector: 'app-publication-display-modal',
  templateUrl: './publication-display-modal.component.html',
  styleUrls: ['./publication-display-modal.component.css'],
})
export class PublicationDisplayModalComponent<T extends boolean> {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { receivedFromApi: T; publications: TPublicationInput<T>[] },
  ) {}
}
