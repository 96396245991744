import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDefenseDetailedResponse } from '../../interfaces/common/defense.interface';
import { combineLatestWith, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import {
  currentUserHeiSelector,
  currentUserIsMonSelector,
  currentUserIsNaqaSelector,
  currentUserIsNotMonOrNaqaSelector,
  userAuthorizedSelector,
} from '../../state/auth/auth.selectors';
import * as moment from 'moment';
import { NameValueType } from '../../elements/name-value-table/name-value-table.component';
import { DefenseState, MessageType } from '../../misc/enums';
import { EdeboOpInfoDto } from '../../interfaces/dict/op.interface';
import { IMessageInfo } from '../../interfaces/messages/message-info.interface';
import { NaqaResetDecisionType } from '../../interfaces/messages/naqa-reset-state.interface';

@Component({
  selector: 'app-defense-display',
  templateUrl: './defense-display.component.html',
  styleUrls: ['./defense-display.component.css'],
})
export class DefenseDisplayComponent {
  defenseResponse: IDefenseDetailedResponse =
    this.route.snapshot.data.defenseResponse;
  edeboOpInfo: EdeboOpInfoDto = this.route.snapshot.data.edeboOpInfo;
  showHeiMessages$ = this.store
    .select(currentUserHeiSelector)
    .pipe(
      map(
        (hei) => this.defenseResponse.defense.op.hei.heiId == (hei?.heiId ?? 0),
      ),
    );
  showMonMessages$ = this.store.select(currentUserIsMonSelector);
  showNaqaMessages$ = this.store.select(currentUserIsNaqaSelector);
  showComplaint$ = this.store.select(currentUserIsNotMonOrNaqaSelector).pipe(
    combineLatestWith(this.store.select(currentUserHeiSelector)),
    map(
      ([value, hei]) =>
        value &&
        this.defenseResponse.defense.op.hei.heiId !== (hei?.heiId ?? 0),
    ),
  );
  messagesButtonEnabled$ = this.store.select(userAuthorizedSelector);
  modifyDefenseAllowed$ = this.store
    .select(currentUserHeiSelector)
    .pipe(
      map(
        (hei) =>
          [DefenseState.Planned, DefenseState.Suspended].includes(
            this.defenseResponse.defense.state,
          ) && this.defenseResponse.defense.op.hei.heiId == (hei?.heiId ?? 0),
      ),
    );
  defenseTimePlaceAllowed$ = this.store
    .select(currentUserHeiSelector)
    .pipe(
      map(
        (hei) =>
          this.defenseResponse.defense.state == DefenseState.Planned &&
          (this.defenseResponse.defense.defenseTime == undefined
            ? this.defenseResponse.defense.op.hei.heiId == (hei?.heiId ?? 0) &&
              moment(
                this.defenseResponse.defense.svrLastDecisionPublicationDate,
              )
                .add(30, 'days')
                .endOf('day') < moment()
            : true),
      ),
    );
  complaintAllowed$ = this.store
    .select(currentUserIsNotMonOrNaqaSelector)
    .pipe(
      map(
        (value) =>
          this.defenseResponse.defense.state == DefenseState.Planned &&
          value &&
          moment(this.defenseResponse.defense.svrLastDecisionPublicationDate)
            .add(15, 'days')
            .endOf('day') > moment(),
      ),
    );
  monAllowed$ = this.store
    .select(currentUserIsMonSelector)
    .pipe(
      map(
        (value) =>
          this.defenseResponse.defense.state == DefenseState.Planned && value,
      ),
    );
  defenseResultsAllowed$ = this.store
    .select(currentUserHeiSelector)
    .pipe(
      map(
        (hei) =>
          this.defenseResponse.defense.state == DefenseState.Planned &&
          this.defenseResponse.defense.op.hei.heiId == (hei?.heiId ?? 0) &&
          this.defenseResponse.defense.defenseTime != undefined &&
          moment(this.defenseResponse.defense.defenseTime) < moment(),
      ),
    );
  heiCancellationAllowed$ = this.store
    .select(currentUserHeiSelector)
    .pipe(
      map(
        (hei) =>
          this.defenseResponse.defense.state ==
            DefenseState.FinishedDegreeAwarded &&
          this.defenseResponse.defense.op.hei.heiId == (hei?.heiId ?? 0),
      ),
    );
  thesisWithdrawalAllowed$ = this.modifyDefenseAllowed$;
  naqaMessagesAllowed$ = this.store
    .select(currentUserIsNaqaSelector)
    .pipe(
      map(
        (value) =>
          (this.defenseResponse.defense.state == DefenseState.FinishedDegreeAwarded && value),
      ),
    );
  naqaResetStateAllowed$ = this.store
    .select(currentUserIsNaqaSelector)
    .pipe(
      map(
        (value) =>
          (this.defenseResponse.defense.state == DefenseState.FinishedDegreeAwarded && value) ||
          (this.defenseResponse.defense.state == DefenseState.ThesisWithdrawn && value) ||
          (this.defenseResponse.defense.state == DefenseState.DegreeCancelledByHei && value) ||
          (this.defenseResponse.defense.state == DefenseState.FinishedDegreeDenied && value)
      ),
    );
  naqaPlanStateAllowed$ = this.store
    .select(currentUserIsNaqaSelector)
    .pipe(
      map(
        (value) =>
          (this.defenseResponse.defense.state != DefenseState.Planned && value)
      ),
    );
  annulmentAllowed$ = this.store
    .select(currentUserHeiSelector)
    .pipe(
      map(
        (hei) =>
          this.defenseResponse.defense.state == DefenseState.Planned &&
          this.defenseResponse.defense.op.hei.heiId == (hei?.heiId ?? 0),
      ),
    );

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {}

  edeboDisplay(edebo: EdeboOpInfoDto): string {
    if (edebo.accreditationType == -2) {
      return 'Освітня програма не знайдена у ЄДЕБО.';
    } else if (edebo.accreditationType == -1) {
      return 'Акредитація відсутня';
    } else if (edebo.accreditationType == 0) {
      return `Строк акредитації сплив ${edebo.expiredOn}`;
    } else if (edebo.accreditationType == 1) {
      if (
        (edebo.certSeries == '–' || edebo.certSeries == '-') &&
        edebo.certNo == '0'
      ) {
        return `Умовна (відкладена) акредитація освітньої програми (Національне агентство), діє до ${edebo.certValidUntil}`;
      } else if (edebo.certSeries == '–' || edebo.certSeries == '-') {
        return `Акредитація освітньої програми (Національне агентство), сертифікат № ${edebo.certNo}, діє до ${edebo.certValidUntil}`;
      } else {
        return `Акредитація освітньої програми (МОН), сертифікат № ${edebo.certSeries} ${edebo.certNo}, діє до ${edebo.certValidUntil}`;
      }
    } else {
      return `Акредитація спеціальності (МОН), сертифікат № ${edebo.certSeries} ${edebo.certNo}, діє до ${edebo.certValidUntil}`;
    }
  }

  generalData(defenseResponse: IDefenseDetailedResponse): NameValueType {
    const { defense } = defenseResponse;
    return [
      { name: 'ПІБ здобувача', value: defense.studentName },
      { name: 'Стать здобувача', value: defense.studentGender },
      {
        name: 'Заклад освіти, освітня програма',
        value: `${defense.op.hei.name}, ОП ${defense.op.opId} ${defense.op.name} (${defense.op.spec})`,
      },
      {
        name: 'Відомості про акредитацію освітньої програми (за даними ЄДЕБО)',
        value: this.edeboDisplay(this.edeboOpInfo),
      },
      {
        name: 'Освітня програма реалізовується у співпраці з іншим закладом освіти',
        value: defense.opInCooperationWithAnotherHei ? 'так' : 'ні',
      },
      { name: 'Дата початку підготовки за ОНП', value: defense.startONPTime },
      { name: 'Дата завершення підготовки за ОНП', value: defense.endONPTime },
      { name: 'Дата завершення навчання на попередньому освітньому рівні', value: defense.endLastEduLevelTime },
      { name: 'Номер облікової картки в державній науковій установі «Український інститут науково-технічної експертизи та інформації»', value: defense.id_UINEI },
      { name: 'Посилання, за яким розміщено текст дисертації на сайті НРАТ УкрІНТЕІ', value: defense.defenseUINEItLink },

    ];
  }

  thesisData(defenseResponse: IDefenseDetailedResponse): NameValueType {
    const { defense } = defenseResponse;
    return [
      {
        name: 'Тема дисертації',
        value: defense.thesisTopic,
      },
      { name: 'Анотація дисертації', value: defense.thesisAnnotation },
      { name: 'Ключові слова', value: defense.thesisKeywords.join(', ') },
    ];
  }

  defenseData(defenseResponse: IDefenseDetailedResponse): NameValueType {
    const { defense } = defenseResponse;
    return [
      {
        name: 'Дата і час захисту',
        value:
          defense.defenseTime !== undefined
            ? moment(defense.defenseTime).format('DD.MM.YYYY HH:mm')
            : 'дані відсутні',
      },
      { name: 'Місце захисту', value: defense.defensePlace || 'дані відсутні' },
    ];
  }

  historyData(defenseResponse: IDefenseDetailedResponse): NameValueType {
    const { defense, messages } = defenseResponse;
    const _ = [
      {
        name: 'Дата рішення про утворення разової ради',
        value: moment(defense.svrCreationDate).format('DD.MM.YYYY'),
      },
      {
        name: 'Дата наказу про введення у дію рішення Вченої ради про утворення разової ради',
        value: moment(defense.svrOrderCreationDate).format('DD.MM.YYYY'),
      },
      {
        name: 'Оприлюднення інформації про утворення разової ради',
        value: moment(defense.svrCreationPublicationDate).format(
          'DD.MM.YYYY HH:mm',
        ),
      },
      {
        name: 'Останнє рішення щодо складу разової ради',
        value: moment(defense.svrLastDecisionDate).format('DD.MM.YYYY'),
      },
      {
        name: 'Оприлюднення інформації про останнє рішення щодо складу разової ради',
        value: moment(defense.svrLastDecisionPublicationDate).format(
          'DD.MM.YYYY HH:mm',
        ),
      },
    ];

    if (
      defense.state == DefenseState.Suspended &&
      defense.suspensionDate != undefined
    ) {
      _.push({
        name: 'Зупинення роботи разової ради (МОН)',
        value: moment(defense.suspensionDate).format('DD.MM.YYYY'),
      });
    }

    if (defense.svrFinalMeetingDate != undefined) {
      _.push({
        name: 'Фінальне засідання разової ради',
        value: moment(defense.svrFinalMeetingDate).format('DD.MM.YYYY'),
      });
    }

    if (defense.heiCancellationDate != undefined) {
      _.push({
        name: 'Рішення закладу про скасування рішення про присудження ступеня',
        value: moment(defense.heiCancellationDate).format('DD.MM.YYYY'),
      });
    }

    if (defense.naqaCancellationDate != undefined) {
      _.push({
        name: 'Рішення Національного агентства про скасування рішення про присудження ступеня',
        value: moment(defense.naqaCancellationDate).format('DD.MM.YYYY'),
      });
    }

    if (defense.annulmentDate != undefined) {
      _.push({
        name: 'Відкликання повідомлення про утворення разової ради',
        value: moment(defense.annulmentDate).format('DD.MM.YYYY HH:mm'),
      });
    }

    let lastResetMessage = null;
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].messageType === MessageType.NaqaResetState) {
          lastResetMessage = messages[i];
          break;
        }
    }
    if (lastResetMessage) {
      console.log(lastResetMessage);
      _.push({
        name: `Оприлюднення результатів захисту Національним агентством`,
        value: moment(lastResetMessage.receivedOn).format('DD.MM.YYYY HH:mm'),
      });
    }

    return _;
  }

  meetingsData(defenseResponse: IDefenseDetailedResponse): NameValueType {
    const { defense } = defenseResponse;
    const _ = [];

    if (
      defense.ethicsCommitteeMeetingDate != undefined &&
      defense.ethicsCommitteeMeetingPlace != undefined
    ) {
      _.push({
        name: 'Засідання Комітету з питань етики',
        value:
          moment(defense.ethicsCommitteeMeetingDate).format(
            'DD.MM.YYYY HH:mm',
          ) + ` (${defense.ethicsCommitteeMeetingPlace})`,
      });
    }

    if (
      defense.appealsCommitteeMeetingDate != undefined &&
      defense.appealsCommitteeMeetingPlace != undefined
    ) {
      _.push({
        name: 'Засідання Апеляційного комітету',
        value:
          moment(defense.appealsCommitteeMeetingDate).format(
            'DD.MM.YYYY HH:mm',
          ) + ` (${defense.appealsCommitteeMeetingPlace})`,
      });
    }

    if (
      defense.naqaMeetingDate != undefined &&
      defense.naqaMeetingPlace != undefined
    ) {
      _.push({
        name: 'Засідання Національного агентства',
        value:
          moment(defense.naqaMeetingDate).format('DD.MM.YYYY HH:mm') +
          ` (${defense.naqaMeetingPlace})`,
      });
    }

    return _;
  }
}
