import { Injectable } from '@angular/core';
import { DatatableStoreTemplate } from '../../misc/store-templates/datatable.store-template';
import { ApiService } from '../../services/api/api.service';
import { IUpdateApiResponse } from '../../interfaces/dict/update.interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';

@Injectable()
export class AllUpdatesStore extends DatatableStoreTemplate<
  IUpdateApiResponse,
  never
> {
  constructor(override api: ApiService, override store: Store<AppState>) {
    super('/update', api, store);
  }
}
