import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveComponentModule } from '@ngrx/component';
import { PublicationsDisplayComponent } from './elements/publications-display/publications-display.component';
import { SvrMembersShowComponent } from './elements/svr-members-show/svr-members-show.component';
import { CommonModule } from '@angular/common';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ShortDefenseDisplayComponent } from './elements/short-defense-display/short-defense-display.component';
import { NameValueTableComponent } from './elements/name-value-table/name-value-table.component';
import { RepresentationsComponent } from './elements/representations/representations.component';

const modules: any[] = [
  CommonModule,
  MaterialModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveComponentModule,
];

const components: any[] = [
  PublicationsDisplayComponent,
  SvrMembersShowComponent,
  ShortDefenseDisplayComponent,
  CustomDatePipe,
  NameValueTableComponent,
  RepresentationsComponent,
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: [...modules, ...components],
})
export class SharedModule {}
