import { createReducer, on } from '@ngrx/store';
import { initialoLoadingState } from './loading.state';
import { loadingOff, loadingOn, loadingZero } from './loading.actions';

export const loadingReducer = createReducer(
  initialoLoadingState,
  on(loadingOn, (state) => ({ tasks: state.tasks + 1 })),
  on(loadingOff, (state) => ({
    tasks: state.tasks == 0 ? state.tasks : state.tasks - 1,
  })),
  on(loadingZero, () => ({ tasks: 0 })),
);
