<h2>NAQA.Svr</h2>

<p>Версія {{ version }}</p>

<p>Технічна підтримка – <a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a></p>

<p>&copy; Микита Євстіфеєв, 2022</p>



