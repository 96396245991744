import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, take } from 'rxjs';
import { ApiService } from '../services/api/api.service';
import { EdeboOpInfoDto } from '../interfaces/dict/op.interface';

@Injectable({
  providedIn: 'root',
})
export class DefenseEdeboOpInfoResolver implements Resolve<EdeboOpInfoDto> {
  constructor(private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<EdeboOpInfoDto> {
    return this.api
      .getEdeboOpInfoByDefenseId(route.params.defenseId)
      .pipe(take(1));
  }
}
