import {
  IHeiApiResponse,
  IHeiDetailedApiResponse,
} from '../../interfaces/dict/hei.interface';

export interface DictState {
  allHeis: IHeiApiResponse[];
  applicantHei: IHeiDetailedApiResponse | null;
}

export const initialDictState: DictState = {
  allHeis: [],
  applicantHei: null,
};
