import { createReducer, on } from '@ngrx/store';
import { initialAuthState } from './auth.state';
import {
  clearToken,
  retrievedToken,
  retrievedTokenError,
} from './auth.actions';

export const authReducer = createReducer(
  initialAuthState,
  on(retrievedToken, (state, { accessToken }) => ({ ...state, accessToken })),
  on(retrievedTokenError, (state) => ({ ...state, accessToken: null })),
  on(clearToken, (state) => ({ ...state, accessToken: null })),
);
