<h3 mat-dialog-title>Публікації</h3>

<mat-dialog-content>
  <app-publications-display
    [receivedFromApi]="data.receivedFromApi"
    [changeable]="false"
    [publications]="data.publications">
  </app-publications-display>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Закрити</button>
</mat-dialog-actions>
