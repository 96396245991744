import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignService } from '../../services/sign/sign.service';
import { CASettings, EndUserConstants } from '../../iit';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { identifyWithBinaryKey } from '../../state/auth/auth.actions';

@Component({
  selector: 'app-identification-file-modal',
  templateUrl: './identification-file-modal.component.html',
  styleUrls: ['./identification-file-modal.component.css'],
})
export class IdentificationFileModalComponent implements OnInit {
  CAs: CASettings[];
  keyFileForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private sign: SignService,
    private dialogRef: MatDialogRef<IdentificationFileModalComponent>,
    private store: Store<AppState>,
  ) {}

  async ngOnInit() {
    this.initForm();
    this.sign.setLibraryType(EndUserConstants.EndUserLibraryType.JS);
    await this.sign.prepareLibrary();
    this.CAs = await this.sign.getCAs();
  }

  initForm() {
    this.keyFileForm = this.fb.group({
      CA: ['', [Validators.required]],
      privateKeyFile: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  async  identify() {
    this.store.dispatch(
      identifyWithBinaryKey({
        privateKeyFile: this.keyFileForm.controls.privateKeyFile.value,
        password: this.keyFileForm.controls.password.value,
        isJks: (
          this.keyFileForm.controls.privateKeyFile.value as File
        ).name.endsWith('.jks'),
        caName: this.keyFileForm.controls.CA.value.issuerCNs[0],
      }),
    );

    this.dialogRef.close();
  }
}
