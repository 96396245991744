import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnumsService } from '../../services/enums/enums.service';
import { TPublicationInput } from '../../types/publication.types';

@Component({
  selector: 'app-publications-display',
  templateUrl: './publications-display.component.html',
  styleUrls: ['./publications-display.component.css'],
})
export class PublicationsDisplayComponent<T extends boolean> {
  @Input() receivedFromApi: T;
  @Input() changeable: boolean;
  @Input() publications: TPublicationInput<T>[] | null | undefined = [];
  @Output() publicationEdit = new EventEmitter<TPublicationInput<T>>();
  @Output() publicationRemoval = new EventEmitter<TPublicationInput<T>>();
  readonly columns = [
    'no',
    'type',
    'icons',
    'description',
    'year',
    'keywords',
    'doi',
    'actions',
  ];

  constructor(public enums: EnumsService) {}

  editPublication(publication: TPublicationInput<T>) {
    this.publicationEdit.emit(publication);
  }

  removePublication(publication: TPublicationInput<T>) {
    this.publicationRemoval.emit(publication);
  }
}
