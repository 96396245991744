<table mat-table
       [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <td mat-cell
        *matCellDef="let _row"
        style="width: 30%">
      {{ _row.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <td mat-cell
        *matCellDef="let _row"
        style="width: 70%">
      <div style="white-space: pre-line;">
        {{ _row.value }}
      </div>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: columns"></tr>

</table>
