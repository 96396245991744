<div class="container">
  <app-header></app-header>

  <main #content>
    <span class="error-message" *ngIf="isDev">Ви працюєте у тестовому середовищі</span>

    <router-outlet></router-outlet>
  </main>
</div>

<ngx-spinner bdColor="rgba(105, 120, 133, 0.8)"
             size = "medium"
             color = "#fff"
             [fullScreen]="true"></ngx-spinner>
