<h3 mat-dialog-title>Фільтр</h3>

<form [formGroup]="allMessagesFilterForm">

  <!--Row 1-->
  <div fxLayout="row" fxLayoutAlign="start end">
    <mat-form-field>
      <mat-label>Тип повідомлення</mat-label>
      <mat-select formControlName="type" multiple>
        <mat-option *ngFor="let type of enums.MessageTypeMap | keyvalue"
                    [value]="type.key">
          {{ type.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--Row 2-->
  <div fxLayout="row" fxLayoutAlign="start end">
    <mat-form-field>
      <mat-label>Дата повідомлення</mat-label>
      <mat-date-range-input [rangePicker]="datePicker">
        <input matStartDate matInput formControlName="dateStart" readonly>
        <input matEndDate matInput formControlName="dateEnd" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #datePicker></mat-date-range-picker>
    </mat-form-field>
  </div>

</form>

<mat-dialog-actions>
  <button mat-button
          [mat-dialog-close]="null">Скасувати</button>
  <button mat-raised-button
          [disabled]="!(filterIsValid$ | ngrxPush)"
          color="primary"
          [mat-dialog-close]="allMessagesFilterForm.value">Застосувати</button>
</mat-dialog-actions>

