import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HeaderComponent } from './main/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PublicationModalComponent } from './modals/publication-modal/publication-modal.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import {
  CUSTOM_DATE_FORMATS,
  NGX_CUSTOM_DATE_FORMATS,
} from './misc/date-formats';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { IdentificationFileModalComponent } from './modals/identification-file-modal/identification-file-modal.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
import { ErrorHandlerService } from './error-handler.service';
import { AllDefensesComponent } from './main/all-defenses/all-defenses.component';
import { AllMessagesComponent } from './main/all-messages/all-messages.component';
import { Store, StoreModule } from '@ngrx/store';
import { AppState, metaReducers, reducers } from './state';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DictEffects } from './state/dict/dict.effects';
import { SignEffects } from './state/sign/sign.effects';
import { AuthEffects } from './state/auth/auth.effects';
import { updateAllHeis } from './state/dict/dict.actions';
import { SharedModule } from './shared.module';
import { IdentificationKeymediaModalComponent } from './modals/identification-keymedia-modal/identification-keymedia-modal.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlUkr } from './misc/paginator-intl';
import { DefenseStateChipComponent } from './elements/defense-state-chip/defense-state-chip.component';
import { MessagesDisplayComponent } from './elements/messages-display/messages-display.component';
import { DefenseDisplayComponent } from './main/defense-display/defense-display.component';
import { PublicationDisplayModalComponent } from './modals/publication-display-modal/publication-display-modal.component';
import { ForbiddenComponent } from './main/forbidden/forbidden.component';
import {
  MAT_CHIPS_DEFAULT_OPTIONS,
  MatChipsDefaultOptions,
} from '@angular/material/chips';
import { ENTER } from '@angular/cdk/keycodes';
import { UpdateComponent } from './main/update/update.component';
import { AllUpdatesComponent } from './main/all-updates/all-updates.component';
import { AboutComponent } from './elements/about/about.component';
import { ErrorEffects } from './state/error/error.effects';
import { LoadingEffects } from './state/loading/loading.effects';
import { AllDefensesFilterComponent } from './modals/all-defenses-filter/all-defenses-filter.component';
import { FilterChipsComponent } from './elements/filter-chips/filter-chips.component';
import { AllMessagesFilterComponent } from './modals/all-messages-filter/all-messages-filter.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    IdentificationFileModalComponent,
    AllDefensesComponent,
    AllMessagesComponent,
    IdentificationKeymediaModalComponent,
    DefenseStateChipComponent,
    MessagesDisplayComponent,
    DefenseDisplayComponent,
    PublicationDisplayModalComponent,
    ForbiddenComponent,
    UpdateComponent,
    AllUpdatesComponent,
    AboutComponent,
    AllDefensesFilterComponent,
    FilterChipsComponent,
    AllMessagesFilterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([
      DictEffects,
      SignEffects,
      AuthEffects,
      ErrorEffects,
      LoadingEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  entryComponents: [
    PublicationModalComponent,
    IdentificationFileModalComponent,
    IdentificationKeymediaModalComponent,
    AllDefensesFilterComponent,
    AllMessagesFilterComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'uk-UA' },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<AppState>) => {
        return () => {
          store.dispatch(updateAllHeis());
        };
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        autoFocus: false,
        hasBackdrop: true,
        width: '70vw',
        height: 'auto',
      } as MatDialogConfig,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
      } as MatSnackBarConfig,
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER],
      } as MatChipsDefaultOptions,
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: NGX_CUSTOM_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
      deps: [Injector],
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlUkr },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
