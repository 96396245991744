<mat-chip-list>

<!--  Захисти-->

  <mat-chip *ngIf="filter.has('text')">
    Текстовий пошук:&nbsp;<strong>{{ filter.get('text') }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('hei')">
    Назва ЗО/НУ:&nbsp;<strong>{{ filter.get('hei') }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('state')">
    Стан захисту:&nbsp;<strong>{{ transformArray('defenseState' ,$any(filter.get('state'))) }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('branches')">
    Галузь знань:&nbsp;<strong>{{ $any(filter.get('branches')).join(' | ') }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('specs')">
    Спеціальність:&nbsp;<strong>{{ $any(filter.get('specs')).join(' | ') }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('defenseDateStart') && filter.has('defenseDateEnd')">
    Дата захисту:&nbsp;<strong>{{ transformMoment($any(filter.get('defenseDateStart'))) }} – {{ transformMoment($any(filter.get('defenseDateEnd'))) }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('svrLastDecisionDateStart') && filter.has('svrLastDecisionDateEnd')">
    Дата останнього рішення щодо разової ради:&nbsp;<strong>{{ transformMoment($any(filter.get('svrLastDecisionDateStart'))) }} – {{ transformMoment($any(filter.get('svrLastDecisionDateEnd'))) }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('complaintsPresent') && filter.get('complaintsPresent')">
    <strong>Захисти з повідомленнями про невідповідність</strong>
  </mat-chip>


<!--  Повідомлення-->

  <mat-chip *ngIf="filter.has('type')">
    Тип повідомлення:&nbsp;<strong>{{ transformArray('message' ,$any(filter.get('type'))) }}</strong>
  </mat-chip>

  <mat-chip *ngIf="filter.has('dateStart') && filter.has('dateEnd')">
    Дата повідомлення:&nbsp;<strong>{{ transformMoment($any(filter.get('dateStart'))) }} – {{ transformMoment($any(filter.get('dateEnd'))) }}</strong>
  </mat-chip>
</mat-chip-list>
