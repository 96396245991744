import { Injectable } from '@angular/core';
import {
  CASettings,
  EndUser,
  EndUserConstants,
  EndUserKeyMedia,
  EndUserLibraryInfoSW,
  EndUserSettings,
} from '../../iit';
import { euSettings } from './sign.service.config';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import {
  clearedPrivateKey,
  readedPrivateKey,
} from '../../state/sign/sign.actions';
import { loadingOff, loadingOn } from '../../state/loading/loading.actions';
import { SignOutput, SignOutputOptions } from '../../types/sign.types';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignService {
  euLibraryType = EndUserConstants.EndUserLibraryType.JS;
  eu?: EndUser;
  readonly euSettings: EndUserSettings = euSettings;

  constructor(private store: Store<AppState>) {}

  /**
   * Встановлення типу бібліотеки.
   * @param type Тип бібліотеки. JS для роботи з файловими ключами, SW - з апаратними.
   */
  setLibraryType(type: EndUserConstants.EndUserLibraryType): void {
    if (this.euLibraryType !== type || !this.isLibraryLoaded) {
      this.euLibraryType = type;
      this.eu = new EndUser('/euscp.worker.js', this.euLibraryType);
      console.log('EUSign library loaded');
    }
  }

  /**
   * Перевірка завантаження бібліотеки.
   */
  get isLibraryLoaded(): boolean {
    return this.eu !== undefined;
  }

  /**
   * Завантажує бібліотеку підпису попередньо обраного типу.
   */
  loadLibrary(): void {
    this.eu = new EndUser('/euscp.worker.js', this.euLibraryType);
    console.log('EUSign library loaded');
  }

  /**
   * Повертає перелік ЦСК.
   */
  async getCAs(): Promise<Array<CASettings>> {
    return this.eu!.GetCAs();
  }

  /**
   * Повертає перелік апаратних носії ключової інформації.
   */
  async getKeyMedias(): Promise<EndUserKeyMedia[]> {
    this.store.dispatch(loadingOn());

    if (
      this.euLibraryType !== EndUserConstants.EndUserLibraryType.SW ||
      !this.isLibraryLoaded
    )
      return [];
    const keyMedias = await this.eu!.GetKeyMedias();

    this.store.dispatch(loadingOff());

    return keyMedias;
  }

  /**
   * Завантажує та ініціалізує бібліотеку підпису.
   */
  async prepareLibrary(): Promise<void> {
    this.store.dispatch(loadingOn());

    const libSW = this.euLibraryType == EndUserConstants.EndUserLibraryType.SW;

    if (!this.isLibraryLoaded) this.loadLibrary();

    if (libSW) {
      const libraryInfo =
        (await this.eu!.GetLibraryInfo()) as EndUserLibraryInfoSW;

      if (!libraryInfo.supported) throw 'EULib not supported';

      if (!libraryInfo.loaded) {
        if (libraryInfo.isNativeLibraryNeedUpdate) throw 'Library needs update';
        if (
          libraryInfo.isWebExtensionSupported &&
          !libraryInfo.isWebExtensionInstalled
        )
          throw 'Web extension not installed';
        throw 'Library not installed';
      }
    }

    if (!(await this.eu!.IsInitialized()))
      await this.eu!.Initialize(this.euSettings);
    await this.eu!.SetRuntimeParameter(
      'SignType',
      EndUserConstants.EndUserSignType.CAdES_X_Long,
    );
    console.log('EUSign initialized');

    this.store.dispatch(loadingOff());
  }

  /**
   * Зчитування особистого ключа у формі файла.
   * @param privateKeyFile Файл особистого ключа. Функція приймає Uint8Array або Blob (в т.ч. File).
   * @param password Пароль до особистого ключа.
   * @param isJks Чи файл особистого ключа представлений у форматі JKS. Якщо у JKS-файлі зберігається декілька ключів, функція зчитує перший з них (за індексом 0).
   * @param caName Назва ЦСК
   */
  async readPrivateKeyBinary(
    privateKeyFile: Uint8Array | Blob,
    password: string,
    isJks: boolean,
    caName: string,
  ): Promise<void> {
    this.store.dispatch(loadingOn());

    if (privateKeyFile instanceof Blob) {
      privateKeyFile = new Uint8Array(await privateKeyFile.arrayBuffer());
    }

    if (await this.eu!.IsPrivateKeyReaded()) {
      await this.eu!.ResetPrivateKey();
      this.store.dispatch(clearedPrivateKey());
    }
    const privateKey = isJks
      ? (await this.eu!.GetJKSPrivateKeys(privateKeyFile))[0].privateKey
      : privateKeyFile;
    const currentPrivateKey = await this.eu!.ReadPrivateKeyBinary(
      privateKey,
      password,
      undefined,
      caName,
    );
    this.store.dispatch(readedPrivateKey({ currentPrivateKey }));

    this.store.dispatch(loadingOff());
  }

  /**
   * Зчитування особистого ключа на апартному носії.
   * @param keyMedia Дані апартного носія у форматі, визначеному в документації бібліотеки підпису.
   * @param caName Назва ЦСК
   */
  async readPrivateKeyMedia(
    keyMedia: EndUserKeyMedia,
    caName: string,
  ): Promise<void> {
    this.store.dispatch(loadingOn());

    if (this.euLibraryType !== EndUserConstants.EndUserLibraryType.SW)
      throw 'EUSign in JS mode, key medias not allowed';
    if (await this.eu!.IsPrivateKeyReaded()) await this.eu!.ResetPrivateKey();
    const currentPrivateKey = await this.eu!.ReadPrivateKey(
      keyMedia,
      undefined,
      caName,
    );
    this.store.dispatch(readedPrivateKey({ currentPrivateKey }));

    this.store.dispatch(loadingOff());
  }

  /**
   * Створення електронного підпису до даних (зовнішнього). Перед задіянням функції необхідно завантажити та проініціалізувати бібліотеку, а також зчитати особистий ключ.
   * @param data Дані для підпису.
   * @param output Формат підпису - Uint8array, Blob чи base64.
   */
  async signData<T extends SignOutputOptions>(
    data: Uint8Array | Blob,
    output: T,
  ): Promise<SignOutput<T>> {

    if (!environment.production) {
      return data as SignOutput<typeof output>;
    }

    this.store.dispatch(loadingOn());

    if (data instanceof Blob) {
      data = new Uint8Array(await data.arrayBuffer());
    }

    let signature;

    switch (output) {
      case 'Uint8Array':
        signature = (await this.eu!.SignData(data, false)) as Uint8Array;
        break;
      case 'Blob':
        signature = new Blob(
          [(await this.eu!.SignData(data, false)) as Uint8Array],
          { type: 'application/pkcs7-signature' },
        );
        break;
      case 'base64':
        signature = (await this.eu!.SignData(data, true)) as string;
        break;
    }

    this.store.dispatch(loadingOff());
    return signature as SignOutput<typeof output>;
  }
}
