import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { currentUserIsNaqaSelector } from '../state/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NaqaGuard implements CanActivate {
  currentUserIsNaqa$ = this.store.select(currentUserIsNaqaSelector);

  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.currentUserIsNaqa$.pipe(
      map((value) => (value ? true : this.router.parseUrl('/forbidden'))),
      take(1),
    );
  }
}
