<header>
  <mat-toolbar color="primary" class="app-toolbar">
    <mat-toolbar-row>
      <img src="assets/logo.png" height="40" class="header-logo" alt="logo">

      <a mat-button type="button" class="header-button" routerLink="/all-defenses">Захисти</a>
      <a mat-button type="button" class="header-button" routerLink="/all-messages">Повідомлення</a>
      <a *ngIf="currentUserIsHei$ | ngrxPush" mat-button type="button" class="header-button" routerLink="/notifications/create-defense">Новий захист</a>
      <button *ngIf="currentUserIsNaqa$ | ngrxPush"mat-button type="button" class="header-button" [matMenuTriggerFor]="adminMenu">Адміністрування</button>
      <button mat-button type="button" class="header-button" (click)="openAbout()" #aboutOpener>Про систему</button>

      <button *ngIf="!environment.production" mat-button type="button" class="header-button" [matMenuTriggerFor]="debugMenu">Debug</button>

      <span class="spacer"></span>

      <span *ngIf="userAuthorized$ | ngrxPush; else userNotAuthorized">
      <button mat-icon-button class="header-button" [matMenuTriggerFor]="logoutMenu">
        <mat-icon class="key-active">vpn_key</mat-icon>
      </button>
    </span>

      <ng-template #userNotAuthorized>
        <span>
          <button mat-icon-button class="header-button" [matMenuTriggerFor]="identificationMenu">
            <mat-icon class="key-blank">vpn_key</mat-icon>
          </button>

        </span>
      </ng-template>

      <mat-menu #identificationMenu>
        <button mat-menu-item (click)="openIdentificationFileModal()">Файловий носій</button>
        <button mat-menu-item (click)="openIdentificationKeymediaModal()">Апаратний носій</button>
      </mat-menu>

      <mat-menu #logoutMenu>
        <button mat-menu-item (click)="logout()">Вийти</button>
      </mat-menu>


      <mat-menu #debugMenu>
        <button mat-menu-item (click)="debugLogin(1)">Login як заклад</button>
        <button mat-menu-item (click)="debugLogin(2)" >Login як працівник NAQA</button>
        <button mat-menu-item (click)="debugLogin(3)">Login як МОН</button>
      </mat-menu>

      <mat-menu #adminMenu>
        <button mat-menu-item routerLink="/update">Завантажити довідник</button>
        <button mat-menu-item routerLink="/all-updates">Історія оновлень</button>
      </mat-menu>

    </mat-toolbar-row>

  </mat-toolbar>
</header>

