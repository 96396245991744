import { Injectable } from '@angular/core';
import { IdentificationService } from '../../services/identification/identification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  identifyWithBinaryKey,
  identifyWithKeyMedia,
  identifyWithDebugParams,
  retrievedToken,
  retrievedTokenError,
} from './auth.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class AuthEffects {
  identifyWithBinaryKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(identifyWithBinaryKey),
      switchMap((action) =>
        this.id
          .identifyWithBinaryKey(
            action.privateKeyFile,
            action.password,
            action.isJks,
            action.caName,
          )
          .then((accessToken) => retrievedToken({ accessToken }))
          .catch((error) => retrievedTokenError({ error })),
      ),
    ),
  );

  identifyWithDebugParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(identifyWithDebugParams),
      switchMap((action) =>
        this.id
          .identifyWithDebugParams(
            action.user
          )
          .then((accessToken) => retrievedToken({ accessToken }))
          .catch((error) => retrievedTokenError({ error })),
      ),
    ),
  );

  identifyWithKeyMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(identifyWithKeyMedia),
      switchMap((action) =>
        this.id
          .identifyWithKeyMedia(action.keyMedia, action.caName)
          .then((accessToken) => retrievedToken({ accessToken }))
          .catch((error) => retrievedTokenError({ error })),
      ),
    ),
  );

  constructor(private actions$: Actions, private id: IdentificationService) {}
}
