import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { DictState } from './dict/dict.state';
import { dictReducer } from './dict/dict.reducer';
import { LoadingState } from './loading/loading.state';
import { loadingReducer } from './loading/loading.reducer';
import { SignState } from './sign/sign.state';
import { signReducer } from './sign/sign.reducer';
import { AuthState } from './auth/auth.state';
import { authReducer } from './auth/auth.reducer';

export interface AppState {
  dict: DictState;
  loading: LoadingState;
  sign: SignState;
  auth: AuthState;
}

export const reducers: ActionReducerMap<AppState> = {
  dict: dictReducer,
  loading: loadingReducer,
  sign: signReducer,
  auth: authReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
