import { Component, Input, OnInit } from '@angular/core';
import { IMessageInfo } from '../../interfaces/messages/message-info.interface';
import { environment } from '../../../environments/environment';
import { EnumsService } from '../../services/enums/enums.service';

@Component({
  selector: 'app-messages-display',
  templateUrl: './messages-display.component.html',
  styleUrls: ['./messages-display.component.css'],
})
export class MessagesDisplayComponent implements OnInit {
  @Input() messages: IMessageInfo[] | undefined;
  @Input() smallIcons: boolean;
  @Input() showLinkToDefense: boolean;
  columns: string[];
  readonly apiUri = environment.apiUri;

  constructor(public enums: EnumsService) {}

  ngOnInit() {
    this.columns = this.showLinkToDefense
      ? ['id', 'type', 'date', 'user', 'link', 'annex', 'linkToDefense']
      : ['id', 'type', 'date', 'user', 'link', 'annex'];
  }
}
