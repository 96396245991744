import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { filter, map, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AllMessagesStore } from './all-messages.store';
import { MatDialog } from '@angular/material/dialog';
import { AllMessagesFilterComponent } from '../../modals/all-messages-filter/all-messages-filter.component';

@Component({
  selector: 'app-all-messages',
  templateUrl: './all-messages.component.html',
  styleUrls: ['./all-messages.component.css'],
  providers: [AllMessagesStore],
})
export class AllMessagesComponent implements OnInit, AfterViewInit, OnDestroy {
  messages$ = this.componentStore.entities();
  messagesNotZero$ = this.componentStore.entitiesNotZero();
  messagesTotalCount$ = this.componentStore.entitiesTotalCount();
  filter$ = this.componentStore.filter();
  filterSet$ = this.filter$.pipe(map((value) => value !== undefined));
  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly apiUri = environment.apiUri;
  _destroying = new Subject<boolean>();

  constructor(
    private componentStore: AllMessagesStore,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.componentStore.setPaginator({ skip: 0, limit: 10 });
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(takeUntil(this._destroying)).subscribe((event) =>
      this.componentStore.setPaginator({
        skip: event.pageSize * event.pageIndex,
        limit: event.pageSize,
      }),
    );
  }

  ngOnDestroy() {
    this._destroying.next(true);
    this._destroying.complete();
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(AllMessagesFilterComponent);

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((value) => !!value),
        map(
          (value) =>
            new Map<any, any>(Object.entries(value).filter(([_, v]) => !!v)),
        ),
      )
      .subscribe((value) => this.componentStore.setFilter(value));
  }

  clearFilter() {
    this.componentStore.clearFilter();
  }
}
