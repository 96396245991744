import { isMoment, Moment } from 'moment';
import { IPublicationState } from '../interfaces/common/publication.interface';
import { ISvrMemberState } from '../interfaces/common/svr-member.interface';
import { IHeiApiResponse } from '../interfaces/dict/hei.interface';
import { ITeacherShortApiResponse } from '../interfaces/dict/teacher.interface';
import {
  IOpApiResponse,
  IOpShortApiResponse,
} from '../interfaces/dict/op.interface';

export function removeFromArray<T>(array: T[], value: T): T[] {
  const index = array.indexOf(value);
  if (index == -1) return array;

  let _array = [...array];
  _array.splice(index, 1);
  return _array;
}

export function addOrPatchEntity<T extends IPublicationState | ISvrMemberState>(
  array: T[],
  value: T,
): T[] {
  const index = array.findIndex((v) => v.uuid == value.uuid);
  if (index < 0) {
    // Додається нова публікація
    return [...array, value];
  } else {
    // Оновлюється існуюча публікація
    return array.map((v, i) => (index == i ? value : v));
  }
}

export function UInt8ArrayToBase64(array: Uint8Array): Promise<string> {
  return new Promise((resolve) => {
    let binary = '';
    let len = array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(array[i]);
    }
    resolve(window.btoa(binary));
  });
}

export function UInt8ArrayToBinary(array: Uint8Array): Promise<string> {
  return new Promise<string>((resolve) => {
    let binary = '';
    let len = array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(array[i]);
    }
    resolve(binary);
  });
}

export function base64ToUInt8Array(base64: string): Promise<Uint8Array> {
  return new Promise((resolve) => {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    resolve(bytes);
  });
}

export function valueIsArray(value: any): value is Array<any> {
  return Array.isArray(value);
}

export function valueIsBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function valueIsMoment(value: any): value is Moment {
  return isMoment(value);
}

export function valueIsString(value: any): value is string {
  return typeof value === 'string';
}

export function compareHeis(h1: IHeiApiResponse, h2: IHeiApiResponse): boolean {
  return h1 && h2 && h1.heiId == h2.heiId;
}

export function compareOps(
  op1: IOpApiResponse | IOpShortApiResponse,
  op2: IOpApiResponse | IOpShortApiResponse,
): boolean {
  return op1 && op2 && op1.opId == op2.opId;
}

export function compareTeachers(
  t1: ITeacherShortApiResponse,
  t2: ITeacherShortApiResponse,
): boolean {
  return t1 && t2 && t1.teacherId == t2.teacherId;
}

export function compareSpec(spec1?: string, spec2?: string): boolean {
  return !!spec1 && !!spec2 && spec1 === spec2;
}
