import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlUkr extends MatPaginatorIntl {
  constructor() {
    super();
    this.firstPageLabel = 'Перша';
    this.lastPageLabel = 'Остання';
    this.nextPageLabel = 'Наступна';
    this.previousPageLabel = 'Попередня';
    this.itemsPerPageLabel = 'Записів на сторінці';
    this.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 з ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `Записи ${startIndex + 1} – ${endIndex} з ${length}`;
    };
  }
}
